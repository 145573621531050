import axios from 'axios'
import router from '@/router/router'

const config = {
  timeout: 60 * 1000,
  headers: { 'Content-Type': 'application/json' }
}

// let memoryAccessToken = null

export class Transport {
  engine;

  mapToResponse = (response) =>
    response.data

  request (conf) {
    return this.engine
      .request(conf)
      .then(this.mapToResponse)
  }

  constructor () {
    // const token = memoryAccessToken
    // if (token) {
    //   config.headers['Authorization'] = `Bearer ${token}`
    // }
    this.engine = axios.create(config)
    this.engine.interceptors.request.use(function (config) {
      config.headers.Language = localStorage.getItem('lang')
      return config
    })

    this.engine.interceptors.response.use(
      response => response,
      async (error) => {
        const originalRequest = error.config
        // const refreshToken = localStorage.getItem('refreshToken')
        // console.log('refreshToken', refreshToken)

        // Проверка на статус ошибки 401 и отсутствие флага retry
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true // Устанавливаем флаг, чтобы избежать циклических запросов

          try {
            // Запрос на обновление токена
            // const accessToken = await authApiService.refreshToken()

            // const { data } = await this.engine.post('/api/player/auth/refreshToken', {})
            await this.engine.post('/api/player/auth/refreshToken', {})
            // const newAccessToken = data.accessToken
            // const newRefreshToken = data.refreshToken

            // Сохраняем новый access токен и обновляем заголовок запроса
            // const newAccessToken = accessToken
            // this.setAuthenticationToken({ accessToken: newAccessToken })

            // originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`

            // Повторный запрос с новым токеном
            return this.engine(originalRequest)
          } catch (refreshError) {
            // Ошибка при обновлении токена: очистка токенов и перенаправление на страницу входа
            // window.location.href = '?modal=auth'
            if (originalRequest.url !== '/api/player/user/me') {
              router.push('?modal=auth')
            }
            return Promise.reject(refreshError)
          }
        }

        return Promise.reject(error)
      }
    )
  }

  setAuthenticationToken ({ accessToken }) {
    if (accessToken) {
      // this.engine.defaults.headers['Authorization'] = `Bearer ${accessToken}`
      // memoryAccessToken = accessToken
    }
  }

  get (url) {
    return this.request(
      {
        method: 'GET',
        url: `${url}`
      }
    )
  }

  delete (url) {
    return this.request(
      {
        method: 'DELETE',
        url: `${url}`
      }
    )
  }

  put (url, data) {
    return this.request(
      {
        method: 'PUT',
        url: `${url}`,
        data
      }
    )
  }

  post (url, data) {
    return this.request(
      {
        method: 'POST',
        url: `${url}`,
        data
      }
    )
  }

  customRequest (conf) {
    return this.engine
      .request(conf)
  }
}

export const apiTransport = new Transport()
