<template>
  <div class="row justify-content-center">
    <div v-for="prov of providers" :key="prov.id"
         v-show="(connect && !isConnected[prov.id]) || !connect"
         @click="openAuthProvider(prov.id)"
         class="social ml-2" :class="[`social-${prov.id}`, `${prov.active ? '' : 'inactive'}`]">
      <a>
        <i class="fab" :class="[`fa-${prov.id}`]"></i>
      </a>
    </div>
  </div>
</template>
<script>

import { mapActions } from 'vuex'
import qs from 'qs'
import { DISBET_OAUTH2_URL } from '@/common/constants'

export default {
  props: ['isConnected', 'connect', 'isReg', 'selectedCurrency'],
  components: {},
  created () {},
  data () {
    return {
      providers: [
        { id: 'telegram', active: 1 },
        { id: 'steam', active: 1 },
        { id: 'google', active: 1 }
      ]
    }
  },
  methods: {
    ...mapActions('auth', ['telegramLogin', 'googleLogin', 'steamLogin', 'oauthProviderLogin']),
    openAuthProvider (provider) {
      const params = { provider, returnUrl: window.location.href, socketId: this.$socket.client.id }
      if (this.isReg) {
        this.setActiveProvider(provider)
        this.$emit('providerSelected', { provider,
          oauthFunc: () => {
            params.currencyId = this.selectedCurrency
            params.isReg = true
            const href = `${DISBET_OAUTH2_URL}/login?${qs.stringify(params)}`
            window.oauthwindow = this.openCenteredWindow(href, 'Casino Auth', 600, 800)
            return window.oauthwindow
          } })
      } else {
        const href = `${DISBET_OAUTH2_URL}/login?${qs.stringify(params)}`
        window.oauthwindow = this.openCenteredWindow(href, 'Casino Auth', 600, 800)
      }
      // this.oauthProviderLogin({ provider })
    },
    setActiveProvider (id) {
      // Установить active: 0 для всех
      this.providers.forEach(provider => {
        provider.active = 0
      })
      // Найти выбранного провайдера и установить active: 1
      const selectedProvider = this.providers.find(provider => provider.id === id)
      if (selectedProvider) {
        selectedProvider.active = 1
      }
    },
    openCenteredWindow (url, title, width, height) {
      // Получаем размеры экрана
      const screenWidth = window.screen.width
      const screenHeight = window.screen.height

      // Рассчитываем координаты для центра экрана
      const left = Math.max(0, (screenWidth - width) / 2)
      const top = Math.max(0, (screenHeight - height) / 2)

      // Параметры окна
      const windowFeatures = `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes,status=yes`

      // Открываем окно
      const newWindow = window.open(url, title, windowFeatures)

      if (newWindow) {
        newWindow.focus() // Фокусируем новое окно
      }

      return newWindow
    }
  }
}
</script>

<style scoped lang="scss">
.fab {
  color: white;
}
.row {
  background: #1C1F29;
  padding: 10px 18px 6px 14px;
  border-radius: 35px;
  display: inline-flex;
}
.inactive {
  opacity: 0.3
}
.social {
  text-align: center;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  cursor: pointer;
  font-size: 23px;
  margin-bottom: 5px;
  color: white;
&-vk {
   background-color: #5181b8;
 }
&-telegram {
   background-color: #40B3E0;
 }
&-facebook {
   background-color: #4267b2;
 }
&-google {
   background-color: #d93a3a;
 }
&-ok {
   background-color: #ef8d2d;
 }
&-steam {
  background-color: #90929f;
}
&:hover {
   opacity: 0.9;
 }
}
</style>
<style>
.social-telegram iframe {
  position: absolute;
  top: -2px;
  left: -2px;
  z-index: 1;
  width: 40px !important;
  cursor: pointer;
  opacity: .01;
}
</style>
