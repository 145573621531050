import { DISBET_AUTH_URL, DISBET_OAUTH2_URL, DISBET_OAUTH_URL } from '../../constants'
import { apiTransport } from './transport'
import qs from 'qs'
class AuthApiService {
  constructor (transport) {
    this.transport = transport
  }

  login (login, password) {
    return this.transport.post(`${DISBET_AUTH_URL}/login`, { login, password }).then(data => {
      // apiTransport.setAuthenticationToken(data)
    })
  }

  loginByTmpToken ({ token, code }) {
    return this.transport.post(`${DISBET_AUTH_URL}/byTempToken`, { token, code })
  }

  loginByTfaToken ({ token, code }) {
    return this.transport.post(`${DISBET_AUTH_URL}/loginByTfaToken`, { token, code })
  }

  oauthLogin (data, provider) {
    return this.transport.post(`${DISBET_OAUTH_URL}/${provider}`, data).then(data => {
      // apiTransport.setAuthenticationToken(data)
    })
  }

  oauthLogin2 ({ provider, returnUrl }) {
    const params = qs.stringify({ provider, returnUrl: returnUrl ?? window.location.href })
    window.location.href = `${DISBET_OAUTH2_URL}/login?${params}`
    // return this.transport.get(`${DISBET_OAUTH2_URL}/login?${params}`)
  }

  logout () {
    return this.transport.post(`${DISBET_AUTH_URL}/logout`).then(data => {
      // apiTransport.setAuthenticationToken({
      //   accessToken: '',
      //   refreshToken: ''
      // })
    })
  }

  refreshToken () {
    return this.transport.post(`${DISBET_AUTH_URL}/refreshToken`)
  }
}

export const authApiService = new AuthApiService(apiTransport)
