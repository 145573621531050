import {
  accountApiService,
  userApiService
} from '@/common/services/apiServices'
import { errorService } from '@/common/services/errorService'
// import { NOTIFICATION_LEVEL } from '@/common/constants'
import Vue from 'vue'

const defaultUserStat = {
  id: undefined,
  auth: false,
  accounts: [],
  profiles: [],
  currentAccount: {
    id: 0,
    balance: 0
  },
  basicCurrency: null,
  nick: '',
  avatar: '',
  showBalanceInUsd: false,
  lang: '',
  userLevel: {
    exp: 0,
    level: 0,
    nextLevelExp: 0
  }
}

export default {
  namespaced: true,
  state: {
    user: defaultUserStat,
    refHash: ''
  },
  getters: {
    refHash: state => state.refHash,
    user (state) {
      return state.user
    },
    getActiveAccounts (state, getters, rootState, rootGetters) {
      return state.user.accounts
        .filter(acc => !acc.blocked && acc.enabled)
        .sort((a, b) => b.balance - a.balance)
        .map(acc => {
          return {
            ...acc,
            currency: rootGetters['currency/getCurrencyById'](acc.currencyId)
          }
        })
    },
    showBalanceInUsd: state => state.user.showBalanceInUsd,
    id (state) {
      return state.user.id
    },
    userLevel (state) {
      return state.user.userLevel
    },
    auth (state) {
      return !!state.user.id
    },
    getAuth (state) {
      return state.user.auth
    },
    nick (state) {
      return state.user.nick
    },
    email (state) {
      return state.user.email
    },
    firstName (state) {
      return state.user.profiles[0].firstName
    },
    lastName (state) {
      return state.user.profiles[0].lastName
    },
    compointStatus (state) {
      return state.user.compointStatus
    },
    compointBalance (state) {
      return state.user.compointBalance
    },
    compointCounter (state) {
      return state.user.compointCounter
    },
    totalCompointConverted (state) {
      return state.user.totalCompointConverted
    },
    balance (state, data) {
      return state.currentAccount.balance
    },
    getBalanceByCurrency (state) {
      return currencyId => {
        return Number(
          state.user.accounts.find(account => account.currencyId === currencyId)
            ?.balance || 0
        ).toFixed(8)
      }
    },
    getLogin (state) {
      const localProfile = state.user.profiles.find(item => item.type.id === 'local')
      return localProfile ? localProfile.login : ''
    },
    usedStarterBonus (state) {
      return state.user.usedStarterBonus
    },
    admin (state) {
      return state.user.admin
    },
    currentAccount (state, getters, rootState, rootGetters) {
      state.user.currentAccount.currency = rootGetters['currency/getCurrencyById'](state.user.currentAccount.currencyId)
      return state.user.currentAccount
    },
    basicCurrency (state, getters, rootState, rootGetters) {
      return state.user.basicCurrency
    },
    accounts (state) {
      return state.user.accounts
    },
    getActiveFacebook (state) {
      return state.user.profiles.find(profile => {
        if (profile.type.id === 'facebook') {
          return profile
        }
      })
    },
    getActiveTelegram (state) {
      return state.user.profiles.find(profile => {
        if (profile.type.id === 'telegram') {
          return profile
        }
      })
    },
    getActiveOpenWallet (state) {
      return state.user.profiles.find(profile => {
        if (profile.type.id === 'openWallet') {
          return profile
        }
      })
    },
    getActiveGoogle (state) {
      return state.user.profiles.find(profile => {
        if (profile.type.id === 'google') {
          return profile
        }
      })
    },
    getActiveSteam (state) {
      return state.user.profiles.find(profile => {
        if (profile.type.id === 'steam') {
          return profile
        }
      })
    },
    getActiveSocials (state) {
      return state.user.profiles.filter(profile => profile.type.id !== 'local')
    },
    getActiveCurrencyIds (state) {
      return state.user.accounts
        .filter(account => account.enabled)
        .map(account => account.currencyId)
    },
    checkRole (state) {
      return (checkRole) => {
        if (!state.user.auth) {
          return false
        }
        return !!state.user.roles.find(role => role.id === checkRole)
      }
    }
  },
  mutations: {
    socket_ACCOUNT (state, [event, data]) {
      switch (event) {
        case 'load':
          state.user.accounts = data
          break
        case 'update':
          const findIndex = state.user.accounts.findIndex(
            account => account.id === data.id
          )
          Vue.set(state.user.accounts, findIndex, data)
          if (state.user.currentAccount.id === data.id) {
            state.user.currentAccount.balance = data.balance
          }
          break
      }
    },
    socket_USER (state, [event, data]) {
      switch (event) {
        case 'load':
          state.user = { ...state.user, ...data }
          break
        case 'update':
          state.user = { ...state.user, ...data }
          break
        case 'notify':
          if (Notification.permission === 'granted') {
            navigator.serviceWorker.getRegistration().then(function (reg) {
              const options = {
                body: 'Сервис Disbet приветствует вас!',
                icon: 'images/example.png',
                vibrate: [100, 50, 100],
                data: {
                  dateOfArrival: Date.now(),
                  primaryKey: 1
                },
                actions: [
                  {
                    action: 'explore',
                    title: 'Explore this new world',
                    icon: 'images/checkmark.png'
                  },
                  {
                    action: 'close',
                    title: 'Close notification',
                    icon: 'images/xmark.png'
                  }
                ]
              }
              reg.showNotification(data.message, options)
            })
          }
          break
      }
    },
    setShowBalanceInUsd (state, data) {
      state.user.showBalanceInUsd = data
    },
    changeLang (state, data) {
      state.user.lang = data
    },
    load (state, data) {
      Object.assign(state.user, data)
    },
    auth (state, data) {
      state.user.auth = data
    },
    updateUser (state, data) {
      state.user = { ...state.user, ...data }
    },
    changeAccount (state, data) {
      state.user.currentAccount = data
    },
    setBasicCurrency (state, data) {
      state.user.basicCurrency = data
    },
    resetUser (state) {
      state.user = { ...defaultUserStat }
    },
    setRefHash (state, data) {
      state.refHash = data
    }
  },
  actions: {
    load (store, data) {
      store.commit('load', data)
      store.commit('auth', data.auth)
    },
    setAuth (store, data) {
      store.commit('auth', data)
    },
    useStarterBonus (store) {
      store.commit('usedStarterBonus', true)
    },
    setTimeActivatePeriodicalBonus (store, data) {
      store.commit('timeActivatePeriodicalBonus', data)
    },
    updateUser (store, data) {
      store.commit('updateUser', data)
    },
    changeAccount (store, { accountId }) {
      return accountApiService.changeAccount(accountId).then(account => {
        store.commit('changeAccount', account)
      })
    },
    async me (store) {
      try {
        const data = await userApiService.me()
        store.commit('load', data)
        if (data.id) {
          store.commit('auth', true)
        }
        // make default account
        if (!data.activeAccountId && data.accounts.length > 0) {
          await store.dispatch('user/changeAccount', { accountId: data.accounts[0].id }, {
            root: true
          })
        } else {
          store.commit('changeAccount', data.accounts.find(acc => acc.id === data.activeAccountId))
          store.commit('setBasicCurrency', data.basicCurrencyId)
        }
        Vue.prototype.$chatra('setIntegrationData', {
          name: data.nick,
          email: data.email,
          userID: data.id
        })
      } catch (e) {
        console.log(e)
        errorService.catch('Error getUserData. ' + e.message)
      }
    },
    registerPhone (store, { refererId, currencyId, promocode, phone, rToken }) {
      return userApiService.registerPhone(
        refererId,
        currencyId,
        promocode,
        phone,
        rToken
      )
    },
    resetUser (store) {
      store.commit('resetUser')
    },
    getAccountVariables () {
      return accountApiService.accountVariables()
    },
    async setAccountEnabled (store, data) {
      try {
        accountApiService.setAccountEnabled(data)
      } catch (e) {
        errorService.catch('Error setAccountEnabled. ' + e.message)
      }
    },
    recoveryPassword (store, { recaptchaToken, email }) {
      return userApiService.recoveryPassword({ recaptchaToken, email })
    },
    changeNickName (store, data) {
      return userApiService.changeNickName(data)
    },
    uploadAvatar (store, data) {
      try {
        userApiService.uploadAvatar(data)
      } catch (e) {
        errorService.catch('Error uploadAvatar. ' + e.message)
      }
    },
    load2FA () {
      return userApiService.load2FA()
    },
    disable2FA (store, { code, password }) {
      return userApiService.disable2FA(code, password)
    },
    enable2FA (store, { code, password }) {
      return userApiService.enable2FA(code, password)
    },
    setRefHash (store, { hash, visitorId }) {
      store.commit('setRefHash', hash)
      if (hash) { return userApiService.linkVisitor({ hash, visitorId }) }
    },
    async setBasicCurrency (store, data) {
      await accountApiService.setBasicCurrency({ currencyId: data })
      store.commit('setBasicCurrency', data)
    }
  }
}
