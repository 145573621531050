import { authApiService } from '@/common/services/apiServices'
import { errorService } from '@/common/services/errorService'
import Auth2Fa from '@/modules/auth/components/auth/Auth2Fa'
import Vue from 'vue'
import { NOTIFICATION_LEVEL } from '@/common/constants'
import router from '@/router/router'
import { i18n } from '@/plugins/i18n/i18n'

export default {
  namespaced: true,
  state: {
    login: '',
    password: '',
    email: '',
    error: null
  },
  getters: {
    getLogin: (state) => state.login,
    getPassword: (state) => state.password,
    getEmail: (state) => state.email,
    getError (state) {
      return state.error
    }
  },
  mutations: {
    setLogin: (state, { login }) => { state.login = login },
    setPassword: (state, { password }) => { state.password = password },
    setEmail: (state, { email }) => { state.email = email },
    setAuth (state) {
      state.isAuth = true
    },
    setError (state, { message }) {
      state.error = message
    },
    clearError (state) {
      state.error = null
    }
  },
  actions: {
    socket_oauth (store, [event, data]) {
      if (event === 'closeOauthWindow') {
        window.oauthwindow.close()
        Vue.noty.error(data.errorMessage)
      }
    },
    async showTfaModal (store, token) {
      Vue.prototype.$modal.show(Auth2Fa, token, {
        clickToClose: false,
        adaptive: true,
        height: '100%',
        width: '100%'
      })
    },
    async loginByTfaToken (store, { token, code }) {
      try {
        await authApiService.loginByTfaToken({ token, code })
        store.dispatch('successLogin')
        router.push('/')
      } catch (e) {
        store.dispatch('notifications/addNotification', {
          level: NOTIFICATION_LEVEL.ERROR,
          text: i18n.t('errors.message_tf')
        }, { root: true })
      }
    },
    async initAuth (store) {
      // наверное надо завести какой то метод (возможно в апп) который синициирует запрос всех нужных данных для всего проекта
      await store.dispatch('user/me', null, { root: true })
    },
    async login (store, { login, password }) {
      try {
        store.commit('clearError')
        await authApiService.login(login, password)
        store.dispatch('successLogin')
        return true
      } catch (e) {
        if (e.response.status === 403) {
          store.dispatch('showTfaModal', { token: e.response.data.token })
        } else if (e.response) {
          store.commit('setError', { message: e.response.data.message })
        } else {
          errorService.catch(`Error login. ${e.message}`)
        }
        return false
      }
    },
    async oauthProviderLogin (store, { provider, returnUrl }) {
      await authApiService.oauthLogin2({ provider, returnUrl })
    },
    async loginByTmpToken (store, { code = '', token }) {
      try {
        store.commit('clearError')
        await authApiService.loginByTmpToken({ code, token })
        if (window.opener) {
          window.opener.postMessage({ type: 'OAUTH_LOGIN', data: true }, '*')
        }
        store.dispatch('successLogin')
        return true
      } catch (e) {
        if (e.response && e.response.status === 403) {
          store.dispatch('showTfaModal', { token: token })
        } else if (e.response) {
          store.commit('setError', { message: e.response.data.message })
        } else {
          errorService.catch(`Error login. ${e.message}`)
        }
        return false
      }
    },
    async steamLogin (store, { query, connect }) {
      const refHash = store.rootGetters['user/refHash']
      let currency = 'RUB' // todo remove this
      return authApiService.oauthLogin({ refHash, currency, query }, connect ? 'steam/connect' : 'steam/login')
        .then(() => {
          store.dispatch('successLogin')
        }).catch(e => {
          errorService.catch(`Error login. ${e.message}`)
        })
    },
    async telegramLogin (store, data) {
      const refHash = store.rootGetters['user/refHash']
      return authApiService.oauthLogin({ refHash, ...data }, 'telegram')
        .then(() => {
          store.dispatch('successLogin')
        }).catch(e => {
          if (e.response.status === 403) {
            store.dispatch('showTfaModal', { token: e.response.data.tfaToken })
          }
        })
    },
    async googleLogin (store, { id, nick, email, token, avatar, connect }) {
      const refHash = store.rootGetters['user/refHash']
      let currency = 'RUB' // todo remove this
      return authApiService.oauthLogin({ refHash, id, nick, email, token, avatar, currency }, connect ? 'googleConnect' : 'googleLogin')
        .then(() => {
          store.dispatch('successLogin')
        }).catch(e => {
          if (e.response.status === 403) {
            store.dispatch('showTfaModal', { token: e.response.data.tfaToken })
          }
        })
    },

    async successLogin (store) {
      await store.dispatch('app/initAppAuth', null, { root: true })
      this.$socket.disconnect()
      this.$socket.connect()
      store.dispatch('user/setRefHash', { hash: null }, { root: true })
      // наверное надо завести какой то метод (возможно в апп) который синициирует запрос всех нужных данных для всего проекта
      await store.dispatch('user/me', null, { root: true })
    },
    async logout (store) {
      return authApiService.logout().then(() => {
        store.dispatch('user/resetUser', null, { root: true })
        // store.dispatch('app/initApp', null, { root: true })
        this.$socket.disconnect()
        this.$socket.connect()
      })
    }

  }
}
