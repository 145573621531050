<template>
  <div class="wrapper slide-out">
    <modals-container/>
    <DisbetPreloaderPage v-if="!connected || !init"/>
    <DisbetMainPage v-if="init"/>
  </div>
</template>

<script>
import './assets/css/reset.css'
import './assets/css/bootstrap.min.css'
import 'vuejs-noty/dist/vuejs-noty.css'
import './assets/css/main.css'
import './assets/css/flags.css'
import './assets/css/animate.css'
import 'primeflex/primeflex.min.css'
import 'primevue/resources/primevue.css'
import './assets/css/theme.css'
import './assets/css/custom.scss'

import { mapActions, mapGetters } from 'vuex'
import DisbetMainPage from '@/common/components/MainPage/MainPage'
import { DisbetPreloaderPage } from '@/common/components'
import Vue from 'vue'

export default {
  components: {
    DisbetPreloaderPage,
    DisbetMainPage
  },
  computed: {
    ...mapGetters('app', {
      init: 'getInit',
      connected: 'getConnected'
    }),
    ...mapGetters('user', ['auth', 'getAuth'])
  },
  methods: {
    ...mapActions('app', {
      initApp: 'initApp'
    }),
    ...mapActions('user', ['me']),
    ...mapActions('auth', ['loginByTmpToken'])
  },
  created: function () {
    this.initApp()
    window.addEventListener('message', (event) => {
      // Проверяем источник сообщения (опционально, для безопасности)
      if (!event.origin.startsWith(window.location.origin)) {
        console.error('Message from unauthorized origin:', event.origin)
        return
      }

      // Получаем данные из сообщения
      const { type, data: rawData } = event.data
      // Проверяем тип сообщения
      switch (type) {
        case 'OAUTH_LOGIN':
          if (window.oauthwindow) {
            window.oauthwindow.close()
          }
          let data
          try {
            data = JSON.parse(Buffer.from(rawData, 'base64').toString('utf8'))
          } catch (err) {
            console.error(err)
            break
          }
          if ('token' in data) {
            let isConnected = !this.auth
            this.loginByTmpToken({ token: data.token })
            if (isConnected) {
              this.me().then(() => {
                this.$router.push('/')
              })
            }
          } else if ('errorMessage' in data) {
            Vue.noty.error(data.errorMessage)
          }
          break
      }
    })
    // test
  }
}
</script>

<style scoped lang="scss">
@import 'assets/css/app';

</style>
