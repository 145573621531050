<template>
  <div class=" d-lg-flex justify-content-end mt-2">
    <div @click="toggleOPFind" class=" mt-3 mb-2 d-lg-none">
          <span style="width: 100%" class="p-input-icon-left">
            <i class="pi pi-search"/>
            <InputText style="width: 100%; background: #15181F;" type="text" :placeholder="$t('header.search')"
                       v-model="searchValue"/>
          </span>
    </div>
    <div class=" d-flex">
      <div class="col-6 p-0">
        <Dropdown
          @change="e => changeProvider(e.value)"
          :options="providers"
          v-model="provider"
          optionLabel="title"
          :filter="true"
          :placeholder="selectedProviderName"
          :showClear="true">
        </Dropdown>
      </div>
      <div class="col-6 p-0 pl-1">
        <Dropdown
          @change="e => changeCategory(e.value)"
          :options="categories"
          v-model="category"
          optionLabel="title"
          :filter="true"
          :placeholder="selectedCategoryName"
          :showClear="true">
        </Dropdown>
      </div>
    </div>
    <OverlayPanel
      :baseZIndex="-50"
      class="tooltip__currencies"
      appendTo="wrapper" ref="find" :showCloseIcon="false"
      style="max-width: 1290px; background: #1C1F29; border-radius: 1.25rem; margin-top: 15px">
      <GameFind :keyword="searchValue"/>
    </OverlayPanel>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import GameFind from '@/common/components/GameFind/GameFind'

export default {
  name: 'Menu',
  components: {
    GameFind
  },
  data: () => ({
    searchValue: '',
    isCategoriesVisible: false,
    producers: [],
    providers: [],
    categories: [],
    type: null,
    producer: null,
    provider: null,
    category: null
  }),
  methods: {
    toggleOPFind (e) {
      this.$refs.find.toggle(e)
    },
    changeProvider (provider) {
      if (provider && this.$route.query.category) {
        this.$router.push({
          path: `/games/${this.$route.params.type}/${provider.name}`,
          query: { category: this.$route.query.category }
        })
      } else if (provider) {
        this.$router.push({
          path: `/games/${this.$route.params.type}/${provider.name}`
        })
      } else if (this.$route.query.category) {
        this.$router.push({
          path: `/games/${this.$route.params.type}/main`,
          query: { category: this.$route.query.category }
        })
      } else {
        this.$router.push({
          path: `/games/${this.$route.params.type}/main`
        })
      }
    },
    changeCategory (category) {
      if (category && this.$route.params.provider) {
        this.$router.push({
          path: `/games/${this.$route.params.type}/${this.$route.params.provider}`,
          query: { category: this.category.name }
        })
      } else if (category) {
        this.$router.push({
          path: `/games/${this.$route.params.type}/main`,
          query: { category: this.category.name }
        })
      } else if (this.$route.params.provider) {
        this.$router.push({
          path: `/games/${this.$route.params.type}/${this.$route.params.provider}`
        })
      } else {
        this.$router.push({
          path: `/games/${this.$route.params.type}/main`
        })
      }
    },
    ...mapActions('game', ['getCategories', 'getProducers', 'getProviders']),
    load () {
      this.getProviders({ type: this.$route.params.type })
        .then(data => {
          this.providers = data.map(cur => ({ ...cur, title: cur.title.charAt(0).toUpperCase() + cur.title.slice(1) }))
          if (this.$route.params.provider) {
            this.provider = this.producers.find(item => item.id === this.$route.params.provider)
          }
        })
      this.getCategories({ type: this.$route.params.type })
        .then(data => {
          this.categories = data.map(cur => ({ ...cur, title: cur.title.charAt(0).toUpperCase() + cur.title.slice(1) }))
          if (this.$route.query.category) {
            this.category = this.categories.find(item => item.id === this.$route.query.category)
          }
        })
    }
  },
  watch: {
    $route () {
      this.load()
    }
  },
  computed: {
    selectedProviderName () {
      return this.$route.params.provider || this.$t('games.casino.providers')
    },
    selectedCategoryName () {
      return (this.category && this.category.id) || this.$t('games.casino.categories')
    }
  },
  beforeMount () {
    this.load()
  }
}
</script>

<style scoped lang="scss">

.tooltip {
  &__currencies {
    background-color: #0F1114;
    border-radius: 15px;
    padding: 10px;
    text-align: center;
    z-index: 10;
    margin-top: 85px !important;
  }

  &__menu {
    background-color: #0F1114;
    border-radius: 15px;
    text-align: center;
    z-index: 10;
  }

  &__currencies-body--item {
    font-size: 15px;
    padding: 10px;
    border-radius: 3px;
    text-align: left;
    color: rgba(0, 0, 0, .75);

    &:hover {
      background-color: #d0d0d0;
    }
  }

  &__currencies-body--item-name {
    margin-right: 6px;
    font-weight: 600;
  }
}

.casino {
  &__menu {
    //position: relative;
    z-index: 1;
    //top: 0;
    //min-width: 300px;
    background-color: rgb(38, 42, 54);
    height: 100%;
    border-radius: 0px 15px 15px 0;
    //max-height: 100vh;
    overflow-y: scroll;

    &--game {
      font-size: 14px;
      cursor: pointer;
      letter-spacing: 0.14px;
      text-transform: capitalize;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      &:hover {
        color: white;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.active {
  color: #fff;
}

.dropdown-trigger-placeholder {
  width: 100%;
}

::v-deep .p-dropdown {
  width: 100%;
  margin: 0;
  padding: 8px;
  background: rgb(21, 24, 31);
  border-radius: 0.7rem;
}

::v-deep .p-dropdown-panel, {
  border-radius: 1rem;
  margin-top: 0.3rem;
}

::v-deep .p-dropdown-items-wrapper {
  border-radius: 0 0 1rem 1rem;

}

::v-deep .p-dropdown-header {
  border-radius: 1rem 1rem 0 0 !important;
  background-color: #1d1e27 !important;
}

::v-deep .p-dropdown-label {
  padding: 5px 5px 5px 1rem;
}

::v-deep .p-dropdown-filter {
  background-color: #15181f !important;

}

::v-deep .p-dropdown-filter {
  height: 10px;
}

@media (max-width: 991px) {
  ::v-deep .p-dropdown-label {
    padding: 15px 5px 5px 1rem;
  }
  .tooltip__currencies {
    margin-top: -50px !important;
  }
}

@media (max-width: 450px) {
  .tooltip__currencies {
    width: calc(100vw - 30px) !important;
  }
}

p-overlaypanel:after, .p-overlaypanel:before {
  left: calc(var(--overlayArrowLeft, 0) + 3.25rem);
}

.p-overlaypanel:after, .p-overlaypanel:before {
  left: calc(var(--overlayArrowLeft, 0) + 3.25rem);
}

</style>
