<template>
  <div class="row mt-4 p-0 m-0 history-global user-select-none">
    <div class="col-12">
      <div class="card row">
        <div class="history-global__header px-3 pt-1 mt-2">
          <div class="live-flashlight">
            <div class="flashlight">
            </div>
            Live
          </div>
          <div class="d-flex history-global__header--items">
            <div :class="{ 'history-global__header--item-active' : nameTable === 'all-bets' }"
                 class="px-2 history-global__header--item" @click="setTable('all-bets')">
              {{ $t('history.all_bets') }}
            </div>
            <div :class="{ 'history-global__header--item-active' : nameTable === 'my-bets' }"
                 class="px-2 history-global__header--item" @click="setTable('my-bets')">
              {{ $t('history.my_bets') }}
            </div>
            <div :class="{ 'history-global__header--item-active' : nameTable === 'big-wins' }"
                 class="d-none d-md-block px-2 history-global__header--item" @click="setTable('big-wins')">
              {{ $t('history.big_wins') }}
            </div>
            <div :class="{ 'history-global__header--item-active' : nameTable === 'cool-moments' }"
                 class="d-none d-md-block px-2 history-global__header--item" @click="setTable('cool-moments')">
              {{ $t('history.cool_moments') }}
            </div>
          </div>
        </div>
        <div class="history-global__body mt-3 mb-3 px-3">
          <DataTable
            class="history-global__table p-datatable-sm d-none d-lg-block"
            :value="data"
            v-if="renderComponent & loading"
            :rows="10">
            <Column field="log.gameName"
                    :header="$t('history.game')">
              <template #body="slotProps">
                <div class="table-title-color">
                  {{ slotProps.data.log.gameName }}
                </div>
              </template>
            </Column>

            <Column field="user.nick"
                    :header="$t('history.gamer')">
              <template #body="slotProps">
                <div class="table-user-color cursor-pointer" @click="opnenModalUser(slotProps.data.user)">
                  {{ slotProps.data.user.nick }}
                </div>
              </template>
            </Column>

            <Column field="log.totalBet"
                    :header="$t('history.bet')">
              <template #body="slotProps">
                {{ (slotProps.data.log.totalBet.toFixed(8)) }}
                <img alt="logo" class="currency-icon" :src="getCurrencyById(slotProps.data.log.currency).src"/>
              </template>
            </Column>

            <Column class="d-none d-md-block" field="log.rate"
                    :header="$t('history.coefficient')">
              <template #body="slotProps">
                {{ slotProps.data.log.rate ? (slotProps.data.log.rate).toFixed(4) : 0 }}<i class="pi pi-times"></i>
              </template>
            </Column>

            <Column field="log.totalWin"
                    :header="$t('history.winnings')">
              <template #body="slotProps">
                <div class="win">
                  <div :class="getStatusClass(slotProps.data.log.totalWin)">
                    {{ (slotProps.data.log.totalWin > 0 ? slotProps.data.log.totalWin : 0) }}
                  </div>
                  <div :class="`${getStatusClass(slotProps.data.log.totalWin)} ml-1`">
                    <img alt="logo" class="currency-icon" :src="getCurrencyById(slotProps.data.log.currency).src"/>
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
          <DataTable :scrollable="true"
            class="history-global__table p-datatable-customers d-block d-lg-none"
            :value="data"
            v-if="renderComponent & loading"
            :rows="10">
            <Column field="log.gameName"
                    :header="$t('history.game')">
              <template #body="slotProps">
                <div class="table-title-color">
                  {{ slotProps.data.log.gameName }}
                </div>
              </template>
            </Column>

            <Column field="user.nick"
                    :header="$t('history.gamer')">
              <template #body="slotProps">
                <div class="table-user-color cursor-pointer" @click="opnenModalUser(slotProps.data.user)">
                  {{ slotProps.data.user.nick }}
                </div>
              </template>
            </Column>

            <Column field="log.totalWin"
                    :header="$t('history.winnings')">
              <template #body="slotProps">
                <div class="win ml-auto">
                  <div :class="getStatusClass(slotProps.data.log.totalWin)">
                    {{ (slotProps.data.log.totalWin > 0 ? slotProps.data.log.totalWin : 0) }}
                  </div>
                  <div :class="`${getStatusClass(slotProps.data.log.totalWin)} ml-1`">
                    <img alt="logo" class="currency-icon" :src="getCurrencyById(slotProps.data.log.currency).src"/>
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
          <div class="data-null" v-if="!loading">
            <p>{{ $t('main.table_data_null') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { toTimeString } from '@/utils/date'
import ModalUserInfo from '@/modules/userStatistic/components/Modal/ModalUserInfo'

export default {
  name: 'HistoryGamesGlobal',
  components: {},
  data () {
    return {
      selectedHistoryGames: [],
      data: [],
      nameTable: 'all-bets',
      betStatus: true,
      renderComponent: true,
      loading: false
    }
  },
  watch: {
    selectedHistoryGames (val) {
      this.loading = val.length > 0
      this.data = val
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },
    historyGamesGlobal () {
      setTimeout(() => {
        this.setTable(this.nameTable)
      }, 500)
    }
  },
  computed: {
    ...mapGetters('user', {
      user: 'user',
      checkAuth: 'auth'
    }),
    ...mapGetters('game', ['historyGamesGlobal', 'bigWins', 'coolMoments', 'userHistoryGames']),
    ...mapGetters('currency', ['getCurrencyById'])
  },
  methods: {
    opnenModalUser (currentUser) {
      this.$modal.show(ModalUserInfo, { currentUser: currentUser.id }, {
        clickToClose: false,
        adaptive: true,
        height: '100%',
        width: '100%'
      })
    },
    setTable (name) {
      switch (name) {
        case 'my-bets':
          this.selectedHistoryGames = this.userHistoryGames
          break
        case 'all-bets':
          this.selectedHistoryGames = this.historyGamesGlobal

          break
        case 'big-wins':
          this.selectedHistoryGames = this.bigWins
          break
        case 'cool-moments':
          this.selectedHistoryGames = this.coolMoments
          break
        default:
          console.error(`setTable = "${name}" is unknown`)
          break
      }
      this.nameTable = name
    },
    selectedHistoryGamesDateTime (date) {
      return toTimeString(new Date(date))
    },
    getGameName (gameId) {
      const gameName = gameId.split(':').pop()
      return gameName
    },
    getStatusClass (state) {
      let className = ''
      if (state > 0) {
        className = 'bet-win'
      } else {
        className = 'bet-lose'
      }
      return className
    }
  },
  created () {
    this.setTable(this.nameTable)
  }
}
</script>

<style lang="scss">

.currency-icon {
  width: 20px;
  height: 20px;
}

tbody tr:first-child {
  -webkit-animation-name: feed_aFirst !important;
  animation-name: feed_aFirst !important;
}

tbody tr:nth-child(odd) {
  -webkit-animation-name: feed_aOdd;
  animation-name: feed_aOdd;
}

tbody tr:nth-child(2n) {
  -webkit-animation-name: feed_aEven;
  animation-name: feed_aEven;
}

tbody tr {
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  will-change: transform, opacity;
}

//.animate {
//  -webkit-animation-fill-mode: forwards;
//  animation-fill-mode: forwards;
//  -webkit-animation-duration: .5s;
//  animation-duration: .5s;
//  -webkit-animation-timing-function: ease-out;
//  animation-timing-function: ease-out;
//  will-change: transform,opacity;
//}
@keyframes feed_aFirst {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes feed_aEven {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes feed_aOdd {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
</style>

<style scoped lang="scss">

.history-global {
  display: flex;
  align-items: center;
  flex-direction: column;

  &__header {
    background: transparent;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    color: #c0c0c0;

    &--items {
      color: #666874;
    }

    &--item {
      cursor: pointer;

      &:hover {
        color: yellow
      }

      &:active {
        color: white;
      }

      &-active {
        color: white;

        &:hover {
          color: white;
        }
      }
    }
  }

  &__body {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 100%;
  }

  &__table {
    font-size: 14px;
    width: 100%;
    justify-content: space-between;
  }
}

.win {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.bet-win {
  color: #b5ff6d;
}

.bet-lose {
  color: #666874;
}

.table-title-color {
  color: white;
}

.table-user-color {
  color: #666874;

  &:hover {
    color: white;
  }
}

.live-flashlight {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff700ef;
}

.flashlight {
  background: #fff700ef;
  border-radius: 50%;
  margin: 0 10px 1.4px 8px;
  height: 14px;
  width: 14px;

  box-shadow: 0 0 0 0 #fff700;
  transform: scale(1);
  animation: pulse 2s infinite;
}
.data-null {
  width: 100%;
  text-align: center;
}
.data-null img {
  display: block;
  margin: 0 auto
}

@keyframes pulse {
  0% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 #fff7007a;
  }

  70% {
    transform: scale(0.90);
    box-shadow: 0 0 0 12px #fff70000;
  }

  100% {
    transform: scale(0.60);
    box-shadow: 0 0 0 0 #fff70000;
  }
}

@media (max-width: 600px) {
  .history-global {
    &__table {
      font-size: 12px;
    }
  }
}

@media (max-width: 440px) {
  .history-global {
    &__table {
      font-size: 10px;
    }
  }
}

@media (max-width: 340px) {
  .history-global {
    &__table {
      font-size: 8px;
    }
  }
}

.pi-times {
  font-size: 10px;
}
@media screen and (max-width: 960px) {
  .p-datatable .p-datatable-tbody>tr>td {
    text-align: left;
    border: 1px solid #3e4053;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
  }
}

</style>
