import { apiTransport } from './transport'

class CashApiService {
  constructor (transport) {
    this.transport = transport
  }

  loadMethodsDeposit () {
    return this.transport.post(`/api/player/banking/payment/method/list`, { codeId: 1 })
  }

  loadBonusDeposit () {
    return this.transport.post(`/api/player/bonus/depositList`)
  }

  loadMethodsWithdraw () {
    return this.transport.post(`/api/player/banking/payment/method/list`, { codeId: 2 })
  }

  getPayWaysList () {
    return this.transport.post(`/api/player/banking/payment/way/list`)
  }

  deposit (methodId, amount) {
    return this.transport.post(`/api/player/banking/cash/deposit`, { methodId, amount })
  }

  getCryptoQRCode (methodId) {
    return this.transport.post(`/api/player/banking/cash/deposit`, { methodId })
  }

  withdraw (methodId, amount, purseNumber) {
    return this.transport.post(`/api/player/banking/cash/withdraw`, { methodId, amount, wallet: purseNumber })
  }

  convert (from, to, amount) {
    return this.transport.post(`/api/player/banking/cash/convert`, { amount: Number(amount), from, to })
  }

  getTransactionsHistory (codeId, take, skip, currencyId) {
    return this.transport.post(`/api/player/banking/cash/transactions`, { codeId, take, skip, currencyId })
  }

  cancelWithdraw (paymentId) {
    return this.transport.post(`/api/player/banking/cash/cancelWithdraw`, { paymentId })
  }
}

export const cashApiService = new CashApiService(apiTransport)
