import { bonusTypes } from '@/utils/constants'

export const ru = {
  navigations: {
    main: 'Главная',
    casino: 'Казино',
    livegames: 'Live игры',
    page_null: 'О-о-о! Страница, которую вы ищете, не может быть найдена!'
  },
  errors: {
    message_tf: 'Ошибка сообщения',
    base: 'Ошибка'
  },
  avatar_modal: {
    edit_avatar: 'Отредактируйте свой аватар',
    default_avatar: 'Аватар по умолчанию',
    invalid_file_type: 'Недопустимый тип файла',
    max_scale: 'Максимальный размер',
    min_scale: 'Минимальный размер',
    choose_image: 'Загрузить аватар',
    button_confirm: 'Подтвердить',
    change: 'Изменить'
  },
  header: {
    online: 'Онлайн',
    auth: 'Войти',
    cashback: 'Кэшбек',
    cashbox: 'Кошелек',
    game_mode: 'Игровые режимы',
    search: 'Игра | Провайдер | Категория'
  },
  avatar: {
    select_avatar: 'Сменить аватар',
    change_avatar: 'Смена аватара'
  },
  main: {
    table_data_null: 'Ой! Данных пока нет!',
    footer: {
      page_rates: 'Домой',
      page_chat: 'Чат',
      page_slots: 'Слоты',
      page_live: 'Live',
      page_bonus: 'Бонус',
      page_more: 'Помощь',
      referral: 'Сотрудничество',
      promotion: 'Акции',
      support: 'Помощь'
    },
    slider: {
      all: 'Все',
      games: 'слота',
      big_win: 'Последний крупный коэфф.'
    }
  },
  bonus: {
    data_null: 'Упс... Доступных бонусов ещё нет, но скоро будут!',
    promo: {
      activate: 'Активировать'
    }
  },
  authentication: {
    forgot_password: 'Забыли пароль?',
    register_title: 'Регистрация',
    register_another_resources: 'Регистрация напрямую через',
    auth_another_resources: 'Войти напрямую через',
    enter_email_valid: 'Введите корректный e-mail: user@example.com',
    enter_email: 'Введите e-mаil',
    enter_login: 'Введите логин',
    enter_password: 'Введите пароль',
    enter_password_minLength: 'минимум 10 символов',
    enter_password_hasSpecialChar: 'Должен быть один спецсимвол',
    enter_login_minLength: 'минимум 6 символов',
    enter_input: 'Подтвердить',
    name_correct: 'Введите корректное имя',
    password_has_uppercase: 'Пароль должен содержать Заглавную букву',
    password_has_lowercase: 'Пароль должен содержать строчную букву',
    password_has_number: 'Пароль должен содержать цифру',
    back_auth: 'Вернуться к авторизации',
    new_password: 'Введите новый пароль',
    enter_new_password: 'Повторите новый пароль',
    recovery_password_title: 'Cбросить пароль учетной записи',
    remember_passord: 'Запомните пароль!',
    login: 'Авторизоваться',
    auth: 'Войти',
    create_account: 'Создать учетную запись',
    recovery_password: 'Восстановить пароль',
    you_login: 'Логин',
    send: 'Отправить',
    save_file: 'Сохранить файл',
    send_to_email: 'Или отправьте на свою почту',
    login_msg: {
      success: 'Успешно',
      email_incorrect: 'Введите верный E-mail'
    },
    register: {
      fast: 'В 1 клик',
      email: 'По е-mail',
      oauth: 'Социальные сети'
    },
    fast_register: {
      welcome: 'Добро пожаловать на Disapp',
      username: 'Ник',
      email: 'E-mail',
      choice_icon: 'Выберите аватар по умолчанию',
      submit: 'Подтвердить',
      register_success: 'Регистрация прошла успешно',
      dont_forget: 'Не забудьте сохранить логин и пароль'
    },
    bonus: 'Взгляни на игры по новому!',
    confirmation_agreement: {
      text_1: 'Я согласен с',
      text_2: 'пользовательским соглашением',
      text_3: 'и подтверждаю, что мне исполнилось 18 лет!'
    }
  },
  cash: {
    bonusTypes: {
      [bonusTypes.FIRST_DEPOSIT]: 'Первый депозит',
      [bonusTypes.SECOND_DEPOSIT]: 'Второй депозит',
      [bonusTypes.THIRD_DEPOSIT]: 'Третий депозит',
      [bonusTypes.AIRDROP]: 'Аирдроп',
      [bonusTypes.DEPOSIT]: 'Депозит',
      [bonusTypes.REGISTER]: 'Регистрация',
      [bonusTypes.SOCIAL]: 'Социальные сети',
      [bonusTypes.REFERRAL]: 'Реферальная программа',
      [bonusTypes.PROMO_CODE]: 'Промокод'
    },
    currency: {
      active_currency_list: 'Список активных валют',
      manage: 'Список валют',
      price: 'ЦЕНА:',
      check: 'Посмотреть в ',
      copy: 'Успешно скопировано',
      choise_currency: 'Выбор валюты',
      choise_pay_system: 'Выберите предпочтительный способ платежа',
      only_active: 'Только активные',
      transaction: 'Транзакции',
      important: 'ВАЖНО:',
      important_text: ' Отправляйте только ',
      important_text_last:
        'на этот депозитный адрес. Отправка любой другой валюты на этот адрес может привести к потере вашего депозита.',
      noty: 'УВЕДОМЛЕНИЕ:',
      noty_text:
        ' Монеты будут депонированы автоматически после 2-х сетевых подтверждений. После внесения депозита вы можете отслеживать его ход по записи депозита.'
    },
    dac: {
      panel: 'Панель DAC',
      what_is_dpd: 'Что такое DAC?',
      this_dpd: 'DAC (Disapp Coin) - это специальная валюта, запущенная компанией disapp.net. Вы можете играть в игры, запускать аирдропы и обменивать на другие валюты',
      how_get_dpd: 'Как получить DAC?',
      get_dpd: 'Вы можете получить DAC через бонус на депозит и другие мероприятия. Вы также можете напрямую обменять другие валюты на доступный баланс DAC через функцию',
      get_dpd_last: ' в кошельке.',
      how_ulock_dpd: 'Как разблокировать DAC?',
      ulock_dpd: 'DAC, полученный с помощью бонуса, необходимо пропорционально разблокировать с помощью ставки.',
      other_games: 'В разных играх разные ставки.',
      how_exchange: 'Можно ли обменять DAC на любую другую валюту?',
      can_exchange: 'Конечно! Самое интересное, что стоимость 1 DAC привязана к цене 1 USD, и вы можете ',
      can_exchange_last: ' DAC в любую другую валюту.',
      how_special_dpd: 'Что особенного в DAC?',
      // special_dpd: 'Вы можете наслаждаться доходностью до 10% в годовом исчислении, когда храните свой DAC в ',
      // special_dpd_last: '. Так что наслаждайтесь хранением DAC!',
      unlocked: 'Доступно',
      history: 'История полученных DAC',
      total: 'Итого',
      lock: 'Заблокировано',
      unlock_process: 'Процесс разблокировки',
      unlock_amount: 'Сумма разблокировки = '
    }
  },
  promo: {
    title: 'Регистрируйся на disapp и получи бонус до',
    welcomeText: 'Добро пожаловать, {nickName}',
    currentLevel: 'Текущий уровень: {currentLevel}',
    toNextLevel: '{exp} Exp до уровня {level}'
  },
  games: {
    list: {
      LW: 'Недавние большие выигрыши',
      MW: 'Большие выигрыши',
      TWG: 'Невероятно повезло',
      TRN: 'В тренде',
      RP: 'Недавно играли',
      BFC: 'Лучшее в казино',
      FBIS: 'Слоты с покупкой бонуса',
      RLG: 'Рекомендуемые Live игры',
      TCG: 'Лучшее в казино',
      HCG: 'Горячие игры в казино',
      BLG: 'Лучшие Live игры',
      TLG: 'Лучшие Live игры',
      HLG: 'Горячие Live игры',
      won: 'Выиграно',
      in: 'в',
      profit: 'Профит'
    },
    casino: {
      more_games: 'Загрузить больше',
      search: 'Найдено',
      game_total: 'игр',
      all_provider: 'Все провайдеры',
      providers: 'Провайдеры',
      categories: 'Категория'
    },
    play: {
      info: 'Вход в игру провайдеров. Баланс выбранной вылюты будет отображаться в {currency}, и если вы измените валюту во время игры, игра обновится и перезагрузится.',
      start: 'Начать игру'
    }
  },
  policy: {
    name: 'Политика конфиденциальности',
    1: 'Пожалуйста, внимательно прочитайте эту Политику конфиденциальности перед использованием https://disapp.net/.',
    2: 'Мы предоставляем доступ к различным играм на нашей платформе. Пользуясь нашими сервисами, Вы доверяете нам свою информацию. Мы понимаем, что это большая ответственность, и прилагаем все усилия, чтобы защитить Ваши данные и дать Вам возможность управлять ими.',
    3: 'В этой Политике конфиденциальности описано, какую информацию мы собираем, с какой целью мы это делаем и как Вы можете обновлять, удалять свои данные и управлять ими.',
    4: 'Информация, которую мы собираем',
    5: '- Имя',
    6: '- Адрес электронной почты',
    7: '- IP-адрес',
    8: '- Номер (а) кошельков из которых были пополнения и выводы',
    9: '- Если Вы создаете аккаунт через социальные сети (fb, google, ok, vk), то, соответственно, мы будем хранить Ваше имя, фотографию профиля, ссылку на страницу в социальные сети.',
    10: 'У каждого игрока, который создает аккаунт, есть логин и пароль. Пароль хранится в зашифрованном виде.',
    11: 'Как мы используем информацию, полученную от игроков',
    12: 'Став игроком, или иным способом предоставив disapp.net персональную информацию, Вы соглашаетесь с тем, что мы собираем и используем Вашу персональную информацию для предоставления нами услуг.',
    13: 'Вы соглашаетесь на предоставления номера телефона и/или адреса электронной почты третьей стороне для маркетинговых целей.',
    14: 'Вы соглашаетесь на предоставление id игры, имени Вашего игрока, ставки, выигрышного коэффициента и выигрыша для публикации в наших социальных сетях.',
    15: 'Вы соглашаетесь с тем, что мы имеем право раскрывать вашу персональную информацию, но лишь с целью и в случаях, если такое требование выдвинуто государственными органами, в предусмотренном действующим законодательством порядке.',
    16: 'Мы получаем информацию от игрока для того, чтобы игрок мог:',
    17: '- пользоваться нашими услугами, в частности, играть в игры и делать ставки;',
    18: '- писать в чате, коммуницировать с другими игроками;',
    19: '- подписаться на еженедельную информационную рассылку;',
    20: '- создавать, управлять, закрывать аккаунт.',
    21: 'В настройках профиля на нашем сайте игрок может:',
    22: '- сделать ставки анонимными;',
    23: '- скрыть сумму ставок собственного профиля;',
    24: '- скрыть историю игр на сайте.',
    25: 'Мы получаем информацию от игрока для того, чтобы мы могли:',
    26: '- идентифицировать Вас в качестве игрока для безопасности и соблюдения наших юридических обязательств;',
    27: '- поддерживать учетную запись игрока;',
    28: '- проверять достоверность информации, предоставленную игроком;',
    29: '- получать вопросы и комментарии от игрока, чтобы реагировать на них и улучшать наши сервисы;',
    30: '- периодически направлять письменные сообщения о важных изменениях в сервисе, вопросах технического обновления и т.д.;',
    31: '- изучать подозрение на незаконную, мошенническую деятельность, связанную с нашими услугами;',
    32: '- сообщать контролирующие органы о совершении преступлений или подозрения преступлений, включая отмывание денег или мошенничество;',
    33: '- повышать безопасность и надежность наших сервисов;',
    34: '- выполнять другие задачи, которые необходимы для выполнения договорных обязательств перед игроком.',
    35: 'Маркетинг',
    36: 'Время от времени мы можем использовать Вашу персональную информацию, чтобы сообщать Вам о наших продуктах или услугах, или о рекламных акциях, которые, по нашему мнению, могут быть Вам интересны или полезны. Мы можем сделать это по электронной почте, SMS или телефону. Если Вы больше не хотите получать маркетинговые или рекламные материалы от disapp.net вообще, или в какой-либо конкретной форме, Вы можете в любое время обратиться к нам по электронной почте support@disapp.net с Вашим запросом, который мы выполним, как только это будет возможно.',
    37: 'Доступ к персональной информации',
    38: 'Вы можете получить доступ к персональной информации, собранной disapp.net, перейдя по ссылке «Настройки» на веб-сайте или связавшись с нами по электронному адресу support@disapp.net Мы предоставим Вам доступ к вашей персональной информации необходимым образом, если это возможно, и в течение разумного периода времени. Если мы отказываем в доступе по таким причинам, мы сообщим Вам в письменном виде об отказе, причинах и механизмах подачи жалоб, которые доступны для Вас.',
    39: 'Управление информацией',
    40: 'Мы примем все разумные меры, чтобы обеспечить сохранность собираемой, используемой или раскрываемой персональной информации игрока.',
    41: 'Доступ к такой информации могут иметь только уполномоченные лица.',
    42: 'disapp.net уничтожит персональную информацию, которой мы владеем, после того, как в ее использовании, хранении или раскрытии больше не будет надобности, но не скорее 5 лет после ее использования.',
    43: 'Управление данной информацией должно происходить согласно действующего законодательства',
    44: 'Безопасность',
    45: 'Вы гарантируете, что применяете все возможные способы для сохранности информации, которую Вы нам передаете.',
    46: 'Вы несете ответственность за безопасность и доступ к Вашему собственному компьютеру, мобильному устройству или любому другому устройству, используемого для получения доступа к сайту.',
    47: 'Вы несете полную ответственность за сохранение секретности Вашего имени пользователя, пароля и любой другой информации об аккаунте.',
    48: 'Мы предпримем все разумные меры для защиты Вашей персональной информации от неправомерного использования, потери и несанкционированного доступа, изменения и раскрытия, в том числе с использованием защищенных паролем систем, баз данных и технологии Secure Socket Layer (SSL).',
    49: 'Сотрудники, агенты и подрядчики disapp.net обязаны соблюдать конфиденциальность персональной информации игроков.',
    50: 'Изменения в этой политике',
    51: 'Мы время от времени меняем эту Политику конфиденциальности, однако не ограничиваем Ваши права, описанные в ней, без вашего четкого согласия. Мы всегда указываем дату публикации последних изменений и предоставляем доступ к архивным версиям. Если изменения существенны, мы сделаем более заметное сообщение (в частности, об изменениях в политике конфиденциальности некоторых сервисов мы сообщаем электронной почтой).',
    52: 'Обращение с жалобами и применимое законодательство',
    53: 'В случае возникновения споров и разногласий наше решение является окончательным и Вы с ним полностью согласны. Все споры и разногласия, возникающие из или в связи с настоящей политикой, разрешаются путем переговоров. Если невозможно достичь соглашения путем переговоров, споры, разногласия и претензии, вытекающие из настоящей политики, разрешаются в соответствии с действующим законодательством Нидерландских Антильских островов. Если у Вас есть какие-либо вопросы, запросы на доступ, или жалобы, связанные с обработкой, Вы можете обратиться к нам через чат поддержки чата или по электронной почте: support@disapp.net.'
  },
  footer: {
    install_disbet_app: 'Установите приложение Disapp',
    responsible_gaming: 'Ответственная игра',
    privacy_policy: 'Политика конфиденциальности',
    helpdesk: 'Центр помощи',
    term_of_use: 'Пользовательское соглашение',
    support: 'Техническая поддержка',
    jobs: 'Вакансии'
  },
  cashbox: {
    header: 'Кошелек',
    history_transact: 'История транзакций',
    payment_states: {
      created: 'Создана',
      completed: 'Успешно',
      processing: 'Ожидание',
      canceled: 'Отменена',
      registered: 'Зарегистрирована',
      rejected: 'Отклонена',
      error: 'Ошибка'
    },
    transaction: {
      header: 'Транзакции',
      deposites: 'Депозиты',
      withdraws: 'Выводы',
      detailed: 'Узнать больше',
      date: 'Дата',
      state: 'Состояние',
      amount: 'Сумма',
      trans: 'Транзакция',
      awaiting: 'В ожидании подтверждения',
      canceled: 'Транзакция отменена',
      completed: 'Завершенный',
      arrived: 'Криптовалюта поступила на ваш аккаунт Disapp. Проверьте баланс в кошельке.',
      send: 'Криптовалюта была отправлена на указанный крипто-кошелек. Ожидайте получения',
      confirmations: 'Подтверждения',
      network: 'Сеть',
      address: 'Адрес',
      head_detailed: 'Детализация',
      help: 'При возникновении проблем свяжитесь с нами,',
      chat: 'поддержка',
      cancele: 'Отменить'
    },
    menu: {
      replenishment_amount: 'Сумма пополнения',
      withdrawal_amount: 'Сумма вывода',
      comission: 'Комиссия',
      withdraw_currency: 'Вывод средств',
      deposite: 'Вклад',
      deposite_pay: 'Перейти к оплате',
      withdraw: 'Вывод',
      withdraw_order: 'Заказать выплату',
      transactions: 'Депозит',
      choice: 'Обмен',
      choice_start: 'Выполнить обмен',
      send_deposit: 'Сделать вклад',
      withdraw_deposit: 'Забрать вклад',
      inserest_rate: 'Годовая процентная ставка',
      send: 'Отправить',
      receive: 'Забрать',
      warning_withdraw:
        'В целях безопасности любые крупные или подозрительные суммы вывода средств будут проверены и обработаны вручную. Процесс ручного аудита обычно занимает 1-6 часов, а при определенных обстоятельствах может занять и больше времени. Как всегда, мы ценим ваше терпение!'
    },
    swap: {
      you_send: 'Вы даете',
      you_get: 'Вы получите примерно',
      time: 'Расчетное время',
      fee: 'Комиссия обмена',
      instant: 'Мгновенно'
    },
    monetary_assets: {
      assets: 'Активы',
      history: 'История',
      receive: 'Забрать',
      invested: 'Вложено',
      created: 'Создан'
    },
    walletModal: {
      currencies: 'Валюты',
      active_balances: 'Активные балансы',
      currency_balances: 'Валютные балансы',
      cryptocurrencies: 'Криптовалюты',
      button_disabled: 'Отключить',
      button_add: 'Добавить'
    },
    select_payment: 'Выберите предпочтительный способ платежа',
    address_for_withdraw: 'Адрес для вывода',
    address_payment: 'Адрес кошелька',
    address_network: 'Выберите сеть',
    minimum_deposit: 'Мин.',
    comment: 'Комментарий',
    required: '(Обязательно указывать)',
    address_crypto_payment: 'Введите адрес вашего криптокошелька',
    help: 'В случае возникновения проблем напишите в нашу',
    support: 'службу поддержки',
    deposite: 'Пополнить',
    choose_currency: 'Выберите валюту',
    balance: 'Баланс',
    more_deposite: 'или больше',
    bonus: {
      first: 'Бонус за первый депозит',
      more_deposite: 'или больше',
      name: 'Бонус',
      more: 'Подробнее',
      good_game: 'Удачной игры и больших вам побед.',
      dep: 'Ооо... На счет поступили средства'
    }
  },
  helpdesk: {
    open_chat: 'Открыть чат',
    time_info_min: 'Среднее время ответа 10 минут',
    time_info_max: 'Среднее время ответа 60 минут',
    support_live: 'Служба поддержки (LIVE)',
    support_email: 'Служба поддержки (E-MAIL)',
    header: 'Операторы работают 24/7',
    terms: {
      name: 'Пользовательское соглашение',
      title: {
        0: 'Определения',
        1: 'Общие',
        2: 'Обязывающие декларации',
        3: 'Ограниченные территории',
        4: 'Общие правила ставок',
        5: 'Бонусы и Акции',
        6: 'Live чат',
        7: 'Ограничение ответственности',
        8: 'Нарушения, штрафы и расторжение'
      },
      text_0_1: 'disapp.net упоминается как "мы" или "нас"',
      text_0_2: 'К Игроку обращаются как "вы" или "Игрок"',
      text_0_3: '«Веб-сайт» disapp.net означает настольные, мобильные или иные платформы, используемые Игроком.',
      text_1_1:
        'Настоящее Соглашение с пользователем распространяется на использование игр, доступных через disapp.net',
      text_1_2:
        'Настоящее Соглашение с пользователем вступает в силу сразу после завершения процедуры регистрации, которая включает в себя установку флажка, принимающего данное Соглашение с пользователем, и успешное создание учетной записи. Используя любую часть Веб-сайта после создания учетной записи, вы соглашаетесь с настоящим Соглашением с пользователем.',
      text_1_3:
        'Перед созданием учетной записи необходимо внимательно ознакомиться с настоящим Соглашением с пользователем. Если вы не согласны с положениями настоящего Соглашения с пользователем, вы не должны создавать учетную запись или продолжать использовать Веб-сайт.',
      text_2_1:
        'Соглашаясь соблюдать настоящее Пользовательское соглашение, вы также соглашаетесь соблюдать Правила disapp.net и Политику конфиденциальности, которые настоящим включены посредством ссылки в настоящее Пользовательское соглашение. В случае каких-либо несоответствий настоящее Пользовательское соглашение будет иметь преимущественную силу. Настоящим Вы заявляете и гарантируете, что:',
      text_2_1_1: 'Вы достигли (a) 18 лет или (b) иного установленного законом возраста, или совершеннолетия, установленного применимыми к вам законами, в зависимости от того, какой возраст больше;',
      text_2_1_2: 'У Вас есть полная дееспособность для заключения юридически обязывающего соглашения с нами, и вы не ограничены какой-либо формой ограниченной дееспособности;',
      text_2_1_3: 'Вся информация, которую вы предоставляете нам в течение срока действия настоящего соглашения, является достоверной, полной, правильной, и вы должны немедленно уведомлять нас о любом изменении такой информации;',
      text_2_1_4: 'Вы несете единоличную ответственность за отчетность и учет любых налогов, применимых к вам в соответствии с соответствующим законодательством, за любые выигрыши, которые вы получаете от нас;',
      text_2_1_5: 'Вы понимаете, что, используя наши услуги, вы рискуете потерять деньги, внесенные на ваш Учетный счет, и соглашаетесь с тем, что вы несете полную и единоличную ответственность за любые такие потери;',
      text_2_1_6: 'В юрисдикции, в которой вы находитесь, вам разрешено пользоваться услугами онлайн-казино;',
      text_2_1_7: 'В отношении депозитов и снятия средств с вашей Учетной записи вы должны использовать только те криптотокены, которые действительны и принадлежат вам на законных основаниях;',
      text_2_1_8: 'Вы понимаете, что стоимость криптовалют может резко меняться в зависимости от рыночной стоимости;',
      text_2_1_9: 'Компьютерное программное обеспечение, компьютерная графика, веб-сайты и пользовательский интерфейс, которые мы предоставляем вам, принадлежат disapp.net или ее партнерам и защищены законами об авторских правах. Вы можете использовать программное обеспечение только в личных, развлекательных целях в соответствии со всеми правилами, пользовательским соглашением, которое мы установили, и в соответствии со всеми применимыми законами, правилами и положениями;',
      text_2_1_10: 'Вы понимаете, что Ethereum не считается законной валютой или платежным средством, и поэтому на Веб-сайте они рассматриваются как виртуальные средства, не имеющие внутренней ценности.',
      text_2_1_11: 'Вы подтверждаете, что Вы не являетесь должностным лицом, директором, сотрудником, консультантом или агентом disapp.net или работаете в любой компании, связанной с disapp.net, или родственником или супругом любого из вышеперечисленных;',
      text_2_1_12: 'Вы не диагностированы и не классифицированы как компульсивный или проблемный игрок. Мы не несем ответственности, если такая проблема возникает при использовании наших услуг, но постараемся сообщить о соответствующей доступной помощи. Мы оставляем за собой право вводить периоды обдумывания, если мы считаем, что такие действия будут полезны.',
      text_2_1_13: 'Вы принимаете и подтверждаете, что мы оставляем за собой право обнаруживать и предотвращать использование запрещенных методов, включая, помимо прочего, обнаружение мошеннических транзакций, автоматическую регистрацию и регистрацию, игровой процесс и методы захвата экрана. Эти шаги могут включать, помимо прочего, проверку свойств устройства Игроков, обнаружение геолокации и маскировки IP-адресов, транзакции и анализ цепочки блоков;',
      text_2_1_14: 'Вы принимаете наше право прекращать и/или изменять любые игры или события, предлагаемые на Веб-сайте, а также отказываться от ставок и/или ограничивать их.',
      text_2_1_15: 'Вы соглашаетесь с тем, что мы имеем право запрещать/блокировать несколько учетных записей и свободно контролировать активы в таких учетных записях.',
      text_2_1_16: 'Вам известно о возможных ошибках или неполноте программного обеспечения, вы соглашаетесь воздержаться от их использования. Кроме того, вы соглашаетесь немедленно сообщать disapp.net о любых ошибках или неполноте. Если вы не выполняете обязательства, изложенные в этом пункте, disapp.net имеет право на полную компенсацию всех расходов, связанных с ошибкой или неполнотой, включая любые расходы, понесенные в связи с соответствующей ошибкой/неполнотой и невыполненным уведомлением пользователя.',
      text_2_1_17: 'Вам известно, что disapp.net имеет право проводить процедуры проверки «KYC» (Знай своего клиента). Доступ к вашей учетной записи пользователя может быть заблокирован или закрыт, если мы решим, что вы предоставили ложную или вводящую в заблуждение информацию.',
      text_2_2:
        'Мы оставляем за собой право объявить ставку частично или полностью недействительной, если disapp.net по своему усмотрению сочтет очевидным, что произошло одно из следующих обстоятельств:',
      text_2_2_1:
        'Вы или люди, связанные с вами, можете прямо или косвенно повлиять на исход события, чтобы получить незаконное преимущество.',
      text_2_2_2:
        'Вы и/или люди, связанные с вами, прямо или косвенно избегаете правил disapp.net',
      text_2_2_3:
        'На результат события прямо или косвенно повлияла преступная деятельность.',
      text_2_2_4:
        'Были сделаны ставки, которые в противном случае не были бы приняты, но которые были приняты в периоды, когда на веб-сайте возникали технические проблемы.',
      text_2_2_5:
        'В связи с такой ошибкой, как ошибка, уязвимости, техническая ошибка, форс-мажорные обстоятельства или иным образом, ставки были предложены, размещены и или приняты из-за этой ошибки.',
      text_2_2_6:
        'Если комиссия за депозит игрока слишком мала и помечена блокчейном или аналогичным сайтом как «недостаточно комиссии для передачи», disapp.net оставляет за собой право конфисковать выигрыш, если disapp.net по своему усмотрению сочтут транзакцию и поведение игрока мошенническим по своему характеру.',
      text_3_1:
        'Территории, включенные в черный список: Китай, Коста-Рика, Нидерланды, Голландские Карибские острова, Кюрасао, Франция, Соединенные Штаты и/или любые другие, ограниченные законом страной или государством. Обратите внимание, что категорически запрещается играть в disapp.net игры в указанных выше странах, включенных в черный список. Настоящим Вы даете согласие на такое раскрытие информации.',
      text_4_1:
        'Ставку может сделать только зарегистрированный владелец аккаунта.',
      text_4_2:
        'Ставку можно сделать только через интернет.',
      text_4_3:
        'Вы можете сделать ставку, только если у вас есть достаточный баланс на вашем счете с disapp.net',
      text_5_1:
        'disapp.net оставляет за собой право отменить любую акцию, бонус или бонусную программу (включая, но не ограничиваясь, бонусами за пополнение счета, приглашением друзей для получения бонусов и программами лояльности) с немедленным вступлением в силу, если мы считаем, что бонус был настроен неправильно или злоупотребляется, и если указанный бонус был после выплаты мы оставляем за собой право отклонить любой запрос на вывод средств и списать такую сумму с вашего счета. Считается ли, что бонус был установлен неправильно или злоупотреблен, определяется исключительно disapp.net',
      text_5_2:
        'Если вы используете Бонус на депозит, снятие вашего первоначального депозита не будет принято до тех пор, пока вы не достигнете требований, предусмотренных Пользовательским соглашением о Бонусе на депозит.',
      text_5_3:
        'disapp.net оставляет за собой право корректировать выплату по ставке, зачисленную на disapp.net счет, если он определяется disapp.net по своему собственному усмотрению, что такая выплата была зачислена из-за ошибки.',
      text_5_4:
        'Все disapp.net предложения предназначены для игроков-любителей и disapp.net может по своему собственному усмотрению ограничить право клиентов участвовать во всех или части любой акции.',
      text_5_5: 'disapp.net оставляет за собой право изменять, отменять, отзывать или отказываться от любой рекламной акции по своему усмотрению.',
      text_5_6: 'Бонусы могут быть получены только один раз на человека/учетную запись, семью, адрес, адрес электронной почты, IP-адреса и среду, в которой компьютеры используются совместно (университет, школа, публичная библиотека, рабочее место и т. д.). Оператор оставляет за собой право закрыть вашу учетную запись и конфисковать любые существующие средства, если будут обнаружены доказательства злоупотребления/мошенничества.',
      text_5_7:
        'Вы признаете и понимаете, что существуют отдельные Пользовательские соглашения в отношении рекламных акций, бонусов и специальных предложений, которые дополняют настоящее Пользовательское соглашение. Настоящее Пользовательское соглашение изложено на соответствующей странице контента на этом веб-сайте или было предоставлено вам лично, в зависимости от обстоятельств. В случае противоречия между положениями таких акций, бонусов и специальных предложений и положениями настоящего Пользовательского соглашения, положения таких акций, бонусов и специальных предложений будут иметь преимущественную силу.',
      text_5_8:
        'Мы можем настаивать на том, чтобы вы поставили определенную сумму своего собственного депозита, прежде чем вы сможете делать ставки на любые бесплатные/бонусные средства, которые мы зачислим на ваш счет.\n',
      text_6_1:
        'В рамках вашего использования Веб-сайта мы можем предоставить вам функцию живого чата, которая модерируется нами и подлежит контролю. Мы оставляем за собой право просматривать чат и вести учет всех заявлений, сделанных на сайте. Вы используете чат-сервис только в развлекательных и социальных целях.',
      text_6_2: 'Мы имеем право удалить функциональность чата или немедленно закрыть вашу Учетную запись Участника и вернуть баланс вашей учетной записи, если вы:',
      text_6_2_a: '(a) делаете какие-либо заявления сексуального характера или грубо оскорбительные, включая выражения нетерпимости, расизма, ненависти или ненормативной лексики;',
      text_6_2_b: '(b) делаете заявления, которые носят оскорбительный, клеветнический, преследующий или оскорбительный характер;',
      text_6_2_c: '(c) используете чат для рекламы, продвижения или иного отношения к любым другим онлайн-объектам;',
      text_6_2_d: '(d) делаете заявления о disapp.net или любых других интернет-сайтах, связанных с Веб-сайтом, которые не соответствуют действительности, и/или являются злонамеренными, и/или наносят ущерб disapp.net;',
      text_6_2_e: '(e) используете чат для сговора, участия в незаконном поведении или поощрения поведения, которое мы считаем крайне неуместным. О любых подозрительных чатах будет сообщено компетентному органу.',
      text_6_3: 'Онлайн-чат используется как форма общения между нами и вами, и его нельзя копировать или передавать каким-либо форумам или третьим лицам.',
      text_7_1:
        'Вы заходите на Веб-сайт и участвуете в Играх на свой страх и риск. Веб-сайт и Игры предоставляются без каких-либо явных или подразумеваемых гарантий.',
      text_7_2:
        'Без ущерба для общего характера предыдущего положения, мы, наши директора, сотрудники, партнеры, поставщики услуг.',
      text_7_3:
        ' Не гарантируем соответствие программного обеспечения, Игр и Веб-сайта их целям.',
      text_7_4:
        'Не гарантируем, что программное обеспечение, Игры и Веб-сайта не содержат ошибок.',
      text_7_5:
        'Не гарантируем, что программное обеспечение, Игры и Веб-сайта будут доступны без перерывов.',
      text_7_6:
        'Не несем ответственности за любые убытки, расходы, издержки или ущерб, будь то прямые, специальные, косвенные, случайные или иные, возникающие в связи с вашим использованием Веб-сайта или вашим участием в Играх.',
      text_7_7:
        'Вы понимаете и признаете, что в случае сбоя в Игре или ее совместимости любые ставки, сделанные во время такого сбоя, будут аннулированы. Средства, полученные в результате неисправной Игры, считаются недействительными, а также любые последующие игровые раунды с указанными средствами, независимо от того, в какие Игры играются с использованием таких средств.',
      text_7_8:
        'Настоящим Вы соглашаетесь полностью возместить и обезопасить нас, наших директоров, сотрудников, партнеров и поставщиков услуг за любые расходы, убытки, ущерб, претензии и обязательства, независимо от причин, которые могут возникнуть в связи с вашим использованием Веб-сайта или участием в Играх.',
      text_7_9:
        'В пределах, разрешенных законом, наша максимальная ответственность, возникающая в результате или в связи с вашим использованием Веб-сайта, независимо от причины действий (будь то договор, правонарушение, нарушение гарантии или иное), не превысит 100 евро.\n',
      text_8_1: 'Если вы нарушаете какие-либо положения настоящего Соглашения с пользователем или у нас есть разумные основания подозревать, что вы их нарушили, мы оставляем за собой право не открывать, не приостанавливать или не закрывать ваш счет Участника, или не задерживать выплату вашего выигрыша и применять такие средства к любому ущербу, причитающемуся вам.',
      text_8_2: 'Вы признаете, что disapp.net принимает окончательное решение о том, нарушали ли вы правила, условия или положения disapp.net таким образом, что это приводит к приостановлению или постоянному запрету на участие в играх нашего сайта.\n'
    },
    faq: {
      name: {
        1: 'Могу ли я зарегистрироваться на вашем сайте?',
        2: 'Что, если я забуду свой пароль?',
        3: 'Я потерял свой мобильный телефон. Как мне сбросить свой Google Authenticator?',
        4: 'Могу ли я изменить свое имя пользователя или зарегистрированный адрес электронной почты?',
        5: 'Как мне стать VIP-персоной?',
        6: 'Как внести депозит?',
        7: 'Как вывести деньги?',
        8: 'Минимальная сумма вывода?',
        9: 'Сколько времени занимает ввод и вывод средств?',
        10: 'Откуда поступают подтверждения транзакций?',
        11: 'Сколько времени требуется для подтверждения транзакции?',
        12: 'Является ли все это честным и справедливым?',
        13: 'Что мне делать, если моя игра зависает или возникла проблема?'
      },
      text: {
        1: 'Вам должно быть не менее 18 лет или вы должны достичь совершеннолетия в соответствии с вашей юрисдикцией. Вам должно быть разрешено играть в онлайн-игры в соответствии с применимыми к вам законами. Для получения дополнительной информации, пожалуйста, ознакомьтесь с нашими условиями. Игры могут вызывать привыкание, поэтому игрокам рекомендуется сохранять самообладание.',
        2: 'Если вы забыли свой пароль, вы можете сбросить его в течение 15 секунд по нашей ссылке «Забыли пароль». После подачи заявки на сброс пароля следуйте инструкциям в электронном письме, которое мы отправим вам, чтобы сбросить пароль.',
        3: 'Если вы хотите удалить свой Google authenticator 2FA, пожалуйста, свяжитесь с нами. После того, как мы получим вашу заявку, для обеспечения безопасности вашей учетной записи вам необходимо правильно ответить на несколько контрольных вопросов, чтобы удалить 2FA.',
        4: 'Если вы настаиваете на изменении своего имени пользователя и / или зарегистрированного адреса электронной почты, вы можете сбросить его в течение 15 секунд по нашей ссылке ”Сбросить адрес электронной почты',
        5: 'Эксклюзивный VIP-клуб доступен только по приглашению. Вы получите уведомление о своем VIP-статусе по электронной почте.',
        6: 'Найдите страницу кошелька, перейдите на страницу пополнения счета, скопируйте адрес кошелька или отсканируйте QR-код для оплаты. Вносите и выводите средства прямо из кооперативного кошелька.',
        7: 'Найдите страницу кошелька, откройте страницу вывода средств, введите адрес кошелька, на который вам нужно вывести, и сумму криптовалюты (обратите внимание на комиссию). После нажатия кнопки подтверждения криптовалюта будет переведена на ваш кошелек (обратите внимание на соответствующую криптовалюту).',
        8: 'Поскольку стоимость каждой криптовалюты разная, минимальная сумма вывода также разная',
        9: 'Каждая транзакция в блокчейне требует нескольких циклов, чтобы подтвердить, что перевод был успешно исполнен. Проще говоря, каждая транзакция требует 5-10 минут, прежде чем она будет подтверждена сетью блокчейнов. Если у вас возникнут какие-либо проблемы во время внесения или вывода средств, вы можете посетить сайт www.blockchain.info, чтобы проверить свою транзакцию, или обратиться в службу технической поддержки.',
        10: 'Вся информация о подтверждении поступает от поставщика кошелька, блокчейна и майнеров',
        11: 'Это зависит от блокчейна и вашей комиссии за перевод. Обычно от 10 минут до нескольких часов.',
        12: 'Мы основаны на смарт-контракте Ethereum. Все данные и базовая логика в блокчейне прозрачны, и манипуляция с ними невозможна. Каждую транзакцию в блокчейне можно отследить на Etherscan. Честность, открытость и справедливость нашей игровой платформы гарантированы',
        13: 'Если вы столкнетесь с какой-либо технической проблемой во время игры, пожалуйста, попробуйте обновить игру. Обычно после обновления проблем нет. Если проблема не исчезнет, пожалуйста, свяжитесь с нами.'
      }
    }
  },
  user_modal: {
    bet_amount: 'Сумма ставок',
    total_games: 'Сыгранно игр',
    best_multiplier: 'Лучший множитель',
    best_winning: 'Лучший выигрыш'
  },
  vip_club: {
    title: 'Vip клубы',
    sub_title: 'Повышайте свой уровень, чтобы получить уникальный доступ к щедрым наградам и персонализированным подаркам!',
    current_level: 'Ваш уровень',
    current: 'Текущий',
    rakeback: 'Рейкбек',
    convert: 'Конвертировать',
    privilege: 'Vip Привилегии',
    current_scores: 'Набранные очки'
  },
  profile: {
    name: 'Настройки учетной записи',
    myprofile: 'Мой профиль',
    promo: 'Ввести промокод',
    helpdesk: 'Центр помощи',
    promo_input: 'Промокод',
    support: 'Чат поддержки',
    active_promo: 'Активируй промокод и получи деньги на свой счет',
    bonus: 'Бонусная система',
    name_user: 'Имя пользователя',
    edit_profile: 'Редактирование профиля',
    user_info: 'Информация о пользователе',
    security: {
      name: 'Безопасность',
      you_login: 'Логин',
      enter_login: 'Укажите логин',
      enter_login_minLength: 'минимум 6 символов',
      new_password: 'Новый пароль',
      text_2fa: 'Введите 6-значный код из приложения',
      confirm_password: 'Повторите пароль',
      enter_password: 'Введите пароль',
      enter_password_minLength: 'минимум 10 символов',
      password_has_uppercase: 'Пароль должен содержать Заглавную букву',
      password_has_lowercase: 'Пароль должен содержать строчную букву',
      password_has_number: 'Пароль должен содержать цифру',
      password_not_match: 'Пароли не совпадают',
      current_password: 'Текущий пароль',
      fa2_auth: 'Двухфакторная авторизация',
      protect_account:
        'Защитите свою учетную запись Disapp дополнительным уровнем безопасности.',
      enable_2FA: 'Включить 2FA',
      disable_2FA: 'Отключить 2FA',
      button_save: 'Сохранить',
      uncorrect_login: 'Некорректный логин'
    },
    session: {
      name: 'Сессии',
      title: 'Сеансы',
      browser: 'Браузер',
      region: 'Регион',
      ip: 'IP-Aдрес',
      when: 'Когда',
      action: 'Действие',
      back: 'Назад',
      forward: 'Вперед',
      current: 'Текущая',
      completed: 'Завершеная'
    },
    fa2: {
      name: 'Двухфакторная аутентификация',
      1: 'Загрузите приложение',
      2: 'Скачайте и установите',
      3: 'или',
      4: 'для вашего телефона или планшета',
      5: 'Отсканируйте QR-код с помощью камеры вашего телефона.',
      6: '2FA КЛЮЧ (РУЧНОЙ ВВОД)',
      7: 'Введите ваш пароль',
      8: 'Введите 6-значный код из приложения',
      9: 'Активировать',
      10: 'Деактивировать',
      11: 'Ключ скопирован в буфер обмена',
      12: 'ОТСКАНИРУЙ QR-код'
    },
    verify: {
      name: 'Верификация',
      change: 'Смена E-mail',
      invalid_file_type: 'Недопустимы тип файла',
      valid_file_type: 'Файл проходит веривикацию'
    },
    general: {
      title: 'Общее',
      min_length: 'Минимум 3 символа',
      email: 'E-mail',
      login: 'Логин',
      button_save: 'Сохранить',
      button_confirm: 'Подтвердить',
      button_sent: 'Отправлено',
      confirm: 'Подтвержден',
      data_correctly: 'Заполните все данные корректно',
      header_local_account: 'Локальную учетная запись'
    },
    other: {
      name: 'Подключенные сервисы',
      social: 'Подключиться к стороннему сервису'
    },
    success: {
      4: 'Успешно'
    },
    error: {
      2: 'Введите корректное имя',
      11: 'Пожалуйста заполните форму'
    }
  },
  chat: {
    chat_input: 'Введите сообщение...',
    not_auth: 'Чтобы пользоваться чатом, нужно авторизоваться сайте',
    error_load_data: 'Ошибка загрузки данных',
    statistic: 'Статистика',
    favorite_games: 'Любимые игры',
    bet: 'Ставок',
    try_luck:
      'Испытай удачу теории вероятности, в игре которая основана на расчете хеш-значения и алгоритме блокчейна.',
    air_drop: {
      this_air_drop: 'Вы можете запустить свой личный эйрдроп любого токена для пользователей в чате.',
      amount_activate: 'Количество активаций',
      sum_air_drop: 'Сумма эйрдропа',
      start_air_drop: 'Запустить',
      name: 'Эйрдроп',
      activated: 'Бонус активирован',
      select_currency: 'Выберите валюту',
      choise_currency: 'Способ оплаты',
      valid_limit: 'Введите количество активаций',
      valid_amount: 'Введите сумму эйрдропа',
      start: 'Запустил эйрдроп, вперед к удаче!',
      give_you: 'Вы получите',
      activation: 'Активаций',
      activate: 'Активировать'
    },
    rules: {
      header: 'Правила чата',
      1: 'Не рассылайте спам, не оскорбляйте других пользователей. Кроме того, избегайте использования ЗАГЛАВНЫХ БУКВ! Никто не любит, когда на него кричат!',
      2: 'Не попрошайничайте и не просите кредитов, аирдропов и переводов.',
      3: 'Не пытайтесь обмануть пользователей.',
      4: 'Не занимайтесь никакими формами рекламы/торговли/продажи/покупки или предложения услуг.',
      5: 'Не используйте сервисы для сокращения URL. Всегда отправляйте исходную ссылку.',
      6: 'Используйте соответствующие языковые каналы.'
    }
  },
  menu: {
    tooltip: {
      settings: 'Настройки',
      level: 'Капрал(3 уровень)',
      more: 'Узнать больше',
      exit: 'Выйти'
    }
  },
  history: {
    title: 'История игр',
    my_bets: 'Мои ставки',
    all_bets: 'Все ставки',
    big_wins: 'Крупные выйгрыши',
    cool_moments: 'Крутые моменты',
    game: 'Игра',
    gamer: 'Игрок',
    time: 'Время',
    bet: 'Ставка',
    coefficient: 'Коэффицент',
    winnings: 'Выигрыш'
  }
}
