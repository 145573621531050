<template>
  <div class="container-fluid">
  </div>
</template>
<script>

export default {
  components: {},
  computed: {}
}
</script>

<style scoped lang="scss">

.container-fluid {
  max-width: 1320px;
}

@media (min-width: 992px) {
  .container-fluid {
    margin-top: 1rem;
  }
}

</style>
