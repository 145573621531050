import { bonusTypes } from '@/utils/constants'

export const ua = {
  navigations: {
    main: 'Головна',
    casino: 'Казино',
    livegames: 'Live ігри',
    page_null: 'О-о-о! Сторінку, яку ви шукаєте, не можна знайти!'
  },
  errors: {
    message_tf: 'Помилка повідомлення',
    base: 'Помилка'
  },
  avatar_modal: {
    edit_avatar: 'Відредагуйте свій аватар',
    default_avatar: 'Аватар за замовчуванням',
    invalid_file_type: 'Неприпустимий тип файлу',
    max_scale: 'Максимальний розмір',
    min_scale: 'Мінімальний розмір',
    choose_image: 'Завантажити аватар',
    button_confirm: 'Підтвердити',
    change: 'Змінити'
  },
  header: {
    online: 'На сайті',
    auth: 'Увійти',
    cashback: 'Кешбек',
    cashbox: 'Гаманець',
    game_mode: 'Ігрові режими',
    search: 'Гра | Провайдер | Категорія'
  },
  avatar: {
    select_avatar: 'Змінити аватар',
    change_avatar: 'Зміна аватара'
  },
  main: {
    table_data_null: 'Ой! Даних поки що немає!!',
    footer: {
      page_rates: 'Додому',
      page_chat: 'Чат',
      page_slots: 'Слоти',
      page_live: 'Live',
      page_bonus: 'Бонус',
      page_more: 'Допомога',
      referral: 'Співпраця',
      promotion: 'Акції',
      support: 'Допомога'
    },
    slider: {
      all: 'Все',
      games: 'слота',
      big_win: 'Останній великий коефіцієнт.'
    }
  },
  tooltip: {
    total: 'Разом',
    unlocked: 'Заблоковано',
    unlock_process: 'Процес розблокування',
    unlock_amount: 'Сума розблокування = '
  },
  bonus: {
    data_null: 'Упс... Доступних бонусів ще немає, але скоро будуть!',
    promo: {
      activate: 'Активувати'
    }
  },
  authentication: {
    forgot_password: 'Забули пароль?',
    register_title: 'Реєстрація',
    register_another_resources: 'Реєстрація безпосередньо через',
    auth_another_resources: 'Увійти безпосередньо через',
    enter_email_valid: 'Введіть правильний e-mail: user@example.com',
    enter_email: 'Введіть e-mail',
    enter_login: 'E-mail адреса',
    enter_password: 'Пароль',
    enter_password_minLength: 'мінімум 10 символів',
    enter_input: 'Підтвердити',
    name_correct: 'Введіть правильне імя',
    password_has_uppercase: 'Пароль повинен містити велику літеру',
    password_has_lowercase: 'Пароль повинен містити маленьку літеру',
    password_has_number: 'Пароль повинен містити цифру',
    back_auth: 'Повернутись до авторизації',
    new_password: 'Введіть новий пароль',
    enter_new_password: 'Повторіть новий пароль',
    recovery_password_title: 'Скинути пароль облікового запису',
    remember_passord: 'Запамятайте пароль!',
    login: 'Авторизуватися',
    auth: 'Увійти',
    create_account: 'Створити обліковий запис',
    recovery_password: 'Відновити пароль',
    you_login: 'Логін',
    send: 'Надіслати',
    save_file: 'Зберегти файл',
    send_to_email: 'Або надішліть на свою пошту',
    login_msg: {
      success: 'Успішно',
      email_incorrect: 'Введіть правильний E-mail'
    },
    register: {
      fast: 'В 1 клік',
      email: 'По е-mail'
    },
    fast_register: {
      welcome: 'Ласкаво просимо на Disapp',
      username: 'Нік',
      email: 'E-mail',
      choice_icon: 'Виберіть аватар за замовчуванням',
      submit: 'Підтвердити',
      register_success: 'Реєстрація пройшла успішно',
      dont_forget: 'Не забудьте зберегти логін та пароль'
    },
    bonus: 'Поглянь на ігри по новому!',
    confirmation_agreement: {
      text_1: 'Я згоден з',
      text_2: 'угодою користувача',
      text_3: 'і підтверджую, що мені виповнилося 18 років!'
    }
  },
  cash: {
    bonusTypes: {
      [bonusTypes.FIRST_DEPOSIT]: 'Перший депозит',
      [bonusTypes.SECOND_DEPOSIT]: 'Другий депозит',
      [bonusTypes.THIRD_DEPOSIT]: 'Третій депозит',
      [bonusTypes.AIRDROP]: 'Аірдроп',
      [bonusTypes.DEPOSIT]: 'Депозит',
      [bonusTypes.REGISTER]: 'Реєстрація',
      [bonusTypes.SOCIAL]: 'Соціальні мережі',
      [bonusTypes.REFERRAL]: 'Реферальна програма',
      [bonusTypes.PROMO_CODE]: 'Промокод'
    },
    currency: {
      active_currency_list: 'Список активних валют',
      manage: 'Список валют',
      price: 'ЦІНА:',
      check: 'Подивитися в ',
      copy: 'Успішна копія',
      choise_currency: 'Вибір валюти',
      choise_pay_system: 'Вибір валюти',
      only_active: 'Тільки активні',
      transaction: 'Транзакції',
      important: 'ВАЖЛИВО:',
      important_text: 'Надсилайте тільки',
      important_text_last:
        'на цю депозитну адресу. Надсилання будь-якої іншої валюти на цю адресу може призвести до втрати вашого депозиту.',
      noty: 'ПОВІДОМЛЕННЯ:',
      noty_text:
        ' Монети будуть депоновані автоматично після 2-х мережних підтверджень. Після внесення депозиту ви можете відстежувати його хід із запису депозиту.'
    },
    dac: {
      panel: 'Панель DAC',
      what_is_dpd: 'Що таке DAC?',
      this_dpd: 'DAC (Disapp Coin) – це спеціальна валюта, запущена компанією disapp.net. Ви можете грати в ігри, запускати аірдропи та обмінювати на інші валюти',
      how_get_dpd: 'Як отримати DAC?',
      get_dpd: 'Ви можете отримати DAC через бонус на депозит та інші заходи. Ви також можете обміняти інші валюти на доступний баланс DAC через функцію',
      get_dpd_last: 'в гаманці.',
      how_ulock_dpd: 'Як розблокувати DAC?',
      ulock_dpd: 'DAC, отриманий за допомогою бонусу, необхідно пропорційно розблокувати за допомогою ставки.',
      other_games: 'У різних іграх різні ставки.',
      how_exchange: 'Чи можна обміняти DAC на будь-яку іншу валюту?',
      can_exchange: 'Звичайно! Найцікавіше, що вартість 1 DAC привязана до ціни 1 USD, і ви можете ',
      can_exchange_last: ' DAC у будь-яку іншу валюту.',
      how_special_dpd: 'Що особливого в DAC?',
      // special_dpd: 'Ви можете насолоджуватися прибутковістю до 10% у річному обчисленні, коли зберігаєте свій DAC в ',
      // special_dpd_last: '. Так що насолоджуйтесь зберіганням DAC!',
      unlocked: 'Доступно',
      history: 'Історія отриманих DAC',
      total: 'Разом',
      lock: 'Заблоковано',
      unlock_process: 'Процес розблокування',
      unlock_amount: 'Сума розблокування = '
    }
  },
  games: {
    list: {
      MW: 'Великі виграші',
      TWG: 'Неймовірно пощастило',
      TRN: 'У тренді',
      RP: 'Нещодавно грали',
      BFC: 'Краще у казино',
      FBIS: 'Слоти з покупкою бонусу',
      RLG: 'Рекомендовані Live ігри',
      TCG: 'Краще у казино',
      HCG: 'Гарячі ігри у казино',
      BLG: 'Кращі Live ігри',
      TLG: 'Кращі Live ігри',
      HLG: 'Гарячі Live ігри',
      won: 'Виграно',
      in: 'в',
      profit: 'Профiт'
    },
    casino: {
      more_games: 'Завантажити більше',
      search: 'Знайдено',
      game_total: 'ігор',
      all_provider: 'Всі провайдери',
      providers: 'Провайдери',
      categories: 'Категорія'
    },
    play: {
      info: 'Вхід до гри провайдерів. Баланс вибраної вилюти буде відображатися у {currency}, і якщо ви зміните валюту під час гри, гра оновиться та перезавантажиться.',
      start: 'Почати гру'
    }
  },
  policy: {
    name: 'Політика конфіденційності',
    1: 'Будь ласка, уважно прочитайте цю Політику конфіденційності перед використанням https://disapp.net/.',
    2: 'Ми надаємо доступ до різних ігор на нашій платформі. Використовуючи наші сервіси, Ви довіряєте нам свою інформацію. Ми розуміємо, що це велика відповідальність, і докладаємо всіх зусиль, щоб захистити Ваші дані та дати Вам можливість керувати ними.',
    3: 'У цій Політиці конфіденційності описано, яку інформацію ми збираємо, з якою метою ми це робимо і як Ви можете оновлювати, видаляти свої дані та керувати ними.',
    4: 'Інформація, яку ми збираємо',
    5: '- Імя',
    6: '- Адреса електронної пошти',
    7: '- IP-адреса',
    8: '- Номер (а) гаманців з яких були поповнення та висновки',
    9: '- Якщо Ви створюєте обліковий запис через соціальні мережі (fb, google, ok, vk), то, відповідно, ми зберігатимемо Ваше імя, фотографію профілю, посилання на сторінку в соціальні мережі.',
    10: 'У кожного гравця, який створює обліковий запис, є логін та пароль. Пароль зберігається у зашифрованому вигляді.',
    11: 'Як ми використовуємо інформацію, отриману від гравців',
    12: 'Ставши гравцем, або іншим способом надавши disapp.net персональну інформацію, Ви погоджуєтесь з тим, що ми збираємо та використовуємо Вашу персональну інформацію для надання послуг.',
    13: 'Ви погоджуєтеся на надання номера телефону та/або адреси електронної пошти третій стороні для маркетингових цілей.',
    14: 'Ви погоджуєтеся на надання id гри, імені Вашого гравця, ставки, виграшного коефіцієнта та виграшу для публікації в наших соціальних мережах.',
    15: 'Ви погоджуєтесь з тим, що ми маємо право розкривати вашу персональну інформацію, але лише з метою та у випадках, якщо така вимога висунута державними органами, у передбаченому чинним законодавством порядку.',
    16: 'Ми отримуємо інформацію від гравця для того, щоб гравець міг:',
    17: '- користуватися нашими послугами, зокрема, грати в ігри та робити ставки;',
    18: '- писати в чаті, спілкуватися з іншими гравцями;',
    19: '- підписатися на щотижневу інформаційну розсилку;',
    20: '- створювати, керувати, закривати обліковий запис.',
    21: 'В налаштуваннях профілю на нашому сайті гравець може:',
    22: '- зробити ставки анонімними;',
    23: '- приховати суму ставок власного профілю;',
    24: '- приховати історію ігор на сайті.',
    25: 'Ми отримуємо інформацію від гравця, щоб ми могли:',
    26: '- ідентифікувати Вас як гравця для безпеки та дотримання наших юридичних зобовязань;',
    27: '- підтримувати обліковий запис гравця;',
    28: '- перевіряти достовірність інформації, надану гравцем;',
    29: '- отримувати питання та коментарі від гравця, щоб реагувати на них та покращувати наші сервіси;',
    30: '- періодично надсилати письмові повідомлення про важливі зміни в сервісі, питання технічного оновлення тощо;',
    31: '- вивчати підозру на незаконну, шахрайську діяльність, повязану з нашими послугами;',
    32: '- повідомляти контролюючі органи про скоєння злочинів або підозри злочинів, включаючи відмивання грошей або шахрайство;',
    33: '- підвищувати безпеку та надійність наших сервісів;',
    34: '- виконувати інші завдання, які необхідні для виконання договірних зобовязань перед гравцем.',
    35: 'Маркетинг',
    36: 'Інколи ми можемо використовувати Вашу персональну інформацію, щоб повідомляти Вам про наші продукти чи послуги, або про рекламні акції, які, на нашу думку, можуть бути Вам цікаві або корисні. Ми можемо зробити це електронною поштою, SMS або телефоном. Якщо Ви більше не хочете отримувати маркетингові або рекламні матеріали від disapp.net взагалі, або в будь-якій конкретній формі, Ви можете будь-коли звернутися до нас електронною поштою support@disapp.net з Вашим запитом, який ми виконаємо, як тільки це буде можливо.',
    37: 'Доступ до персональної інформації',
    38: 'Ви можете отримати доступ до персональної інформації, зібраної disapp.net, перейшовши за посиланням «Налаштування» на веб-сайті або звязавшись з нами на електронну адресу support@disapp.net Ми надамо Вам доступ до вашої персональної інформації необхідним чином, якщо це можливо і протягом розумного періоду часу. Якщо ми відмовляємо у доступі з таких причин, ми повідомимо Вам письмово про відмову, причини та механізми подання скарг, які доступні для Вас.',
    39: 'Управління інформацією',
    40: 'Ми вживемо всіх розумних заходів, щоб забезпечити збереження персональної інформації гравця, що збирається, використовується або розкривається.',
    41: 'Доступ до такої інформації можуть мати лише уповноважені особи.',
    42: 'disapp.net знищить персональну інформацію, якою ми володіємо, після того, як у її використанні, зберіганні або розкритті більше не буде потреби, але не швидше за 5 років після її використання.',
    43: 'Управление данной информацией должно происходить согласно действующего законодательства',
    44: 'Безпека',
    45: 'Ви гарантуєте, що застосовуєте всі можливі способи збереження інформації, яку Ви нам передаєте.',
    46: 'Ви несете відповідальність за безпеку та доступ до Вашого власного компютера, мобільного пристрою або будь-якого іншого пристрою, який використовується для отримання доступу до сайту.',
    47: 'Ви несете повну відповідальність за збереження таємності Вашого імені користувача, пароля та будь-якої іншої інформації про обліковий запис.',
    48: 'Ми вживаємо всіх розумних заходів для захисту Вашої персональної інформації від неправомірного використання, втрати та несанкціонованого доступу, зміни та розкриття, у тому числі з використанням захищених паролем систем, баз даних та технології Secure Socket Layer (SSL).',
    49: 'Співробітники, агенти та підрядники disapp.net зобовязані дотримуватися конфіденційності персональної інформації гравців.',
    50: 'Зміни у цій політиці',
    51: 'Ми час від часу змінюємо цю Політику конфіденційності, однак не обмежуємо Ваші права, описані в ній, без вашої чіткої згоди. Ми завжди вказуємо дату публікації останніх змін та надаємо доступ до архівних версій. Якщо зміни суттєві, ми зробимо більш помітне повідомлення (зокрема, про зміни у політиці конфіденційності деяких сервісів ми повідомляємо електронною поштою).',
    52: 'Звернення зі скаргами та застосовне законодавство',
    53: 'У разі виникнення суперечок та розбіжностей наше рішення є остаточним і Ви з ним повністю погоджуєтеся. Усі суперечки та розбіжності, що виникають із або у звязку з цією політикою, вирішуються шляхом переговорів. Якщо неможливо досягти угоди шляхом переговорів, суперечки, розбіжності та претензії, що випливають із цієї політики, вирішуються відповідно до чинного законодавства Нідерландських Антильських островів. Якщо у Вас є питання, запити на доступ, або скарги, повязані з обробкою, Ви можете звернутися до нас через чат підтримки чату або електронною поштою: support@disapp.net.'
  },
  footer: {
    install_disbet_app: 'Встановіть програму Disapp',
    responsible_gaming: 'Відповідальна гра',
    privacy_policy: 'Політика конфіденційності',
    helpdesk: 'Центр допомоги',
    term_of_use: 'Угода користувача',
    support: 'Технічна підтримка',
    jobs: 'Вакансії'
  },
  cashbox: {
    header: 'Гаманець',
    history_transact: 'Історія транзакцій',
    payment_states: {
      created: 'Створена',
      completed: 'Успішно',
      processing: 'Чекання',
      canceled: 'Скасовано',
      registered: 'Зареєстрована',
      rejected: 'Відхилена',
      error: 'Помилка'
    },
    transaction: {
      header: 'Транзакції',
      deposites: 'Депозит',
      withdraws: 'Вывод',
      detailed: 'Узнать більше',
      date: 'Дата',
      state: 'Стан',
      amount: 'Сума',
      trans: 'Транзакція',
      awaiting: 'В очікуванні підтвердження',
      canceled: 'Транзакція скасована',
      completed: 'Завершений',
      arrived: 'Криптовалюта надійшла на ваш обліковий запис Disapp. Перевірте баланс у гаманці.',
      send: 'Crypto has been sent to конкретний crypto wallet. Expect receipt',
      confirmations: 'Криптовалюта була відправлена на вказаний крипто-гаманець. Чекайте на отримання',
      network: 'Мережа',
      address: 'Адрес',
      head_detailed: 'Деталізація',
      help: 'Якщо у вас виникнуть проблеми, будь ласка, зв’яжіться',
      chat: 'подержка',
      cancel: 'Скасувати'
    },
    menu: {
      replenishment_amount: 'Сума поповнення',
      withdrawal_amount: 'Сума виведення',
      комісія: 'Комісія',
      withdraw_currency: 'Виведення коштів',
      deposite: 'Вклад',
      deposite_pay: 'Перейти до оплати',
      withdraw: 'Висновок',
      withdraw_order: 'Замовити виплату',
      transactions: 'Депозит',
      choice: 'Обмін',
      choice_start: 'Виконати обмін',
      send_deposit: 'Зробити внесок',
      withdraw_deposit: 'Забрати вклад',
      inserest_rate: 'Річна процентна ставка',
      send: 'Надіслати',
      receive: 'Забрати',
      warning_withdraw:
        'З метою безпеки будь-які великі або підозрілі суми виведення коштів будуть перевірені та оброблені вручну. Процес ручного аудиту зазвичай займає 1-6 годин, а за певних обставин може зайняти більше часу. Як завжди, ми цінуємо ваше терпіння!'
    },
    swap: {
      you_send: 'Ви даєте',
      you_get: 'Ви отримуєте приблизно',
      time: 'Расчетное время',
      fee: 'Комісія обміну',
      instant: 'Мгновенно'
    },
    monetary_assets: {
      assets: 'Активи',
      history: 'Історія',
      receive: 'Забрати',
      invested: 'Вкладено',
      created: 'Створено'
    },
    walletModal: {
      currencies: 'Валюти',
      active_balances: 'Активні баланси',
      currency_balances: 'Валютні баланси',
      cryptocurrencies: 'Криптовалюти',
      button_disabled: 'Вимкнути',
      button_add: 'Додати'
    },
    select_payment: 'Виберіть платіжну систему',
    address_for_withdraw: 'Адреса для виведення',
    address_payment: 'Адреса гаманця',
    address_network: 'Виберіть мережу',
    minimum_deposit: 'Мін.',
    comment: 'Коментар',
    required: '(Обов\'язково вказувати)',
    address_crypto_payment: 'Введіть адресу вашого криптогаманця',
    help: 'У разі виникнення проблем напишіть у нашу',
    support: 'службу підтримки',
    deposite: 'Поповнити',
    choose_currency: 'Виберіть валюту',
    balance: 'Баланс',
    more_deposite: 'або більше',
    bonus: {
      first: 'Бонус за перший депозит',
      more_deposite: 'або більше',
      name: 'Бонус',
      more: 'Детальніше',
      good_game: 'Удачной ігри і великих вам перемог.',
      dep: 'Ооо... На рахунок поступили кошти'
    }
  },
  helpdesk: {
    open_chat: 'Відкрити чат',
    time_info_min: 'Середній час відповіді 10 хвилин',
    time_info_max: 'Середній час відповіді 60 хвилин',
    support_live: 'Служба підтримки (LIVE)',
    support_email: 'Служба підтримки (E-MAIL)',
    header: 'Оператори працюють 24/7',
    terms: {
      name: 'Користувача угода',
      title: {
        0: 'Визначення',
        1: 'Загальні',
        2: 'Зобовязуючі декларації',
        3: 'Обмежені території',
        4: 'Загальні правила ставок',
        5: 'Бонуси та Акції',
        6: 'Live чат',
        7: 'Обмеження відповідальності',
        8: 'Порушення, штрафи та розірвання'
      },
      text_0_1: 'disapp.net згадується як "ми" або "нас"',
      text_0_2: 'До Гравця звертаються як "ви" або "Гравець"',
      text_0_3: 'Веб-сайт disapp.net означає настільні, мобільні або інші платформи, що використовуються Гравцем.',
      text_1_1:
        'Ця Угода з користувачем поширюється на використання ігор, доступних через disapp.net',
      text_1_2:
        'Ця Угода з користувачем набирає чинності відразу після завершення процедури реєстрації, яка включає установку прапорця, який приймає цю Угоду з користувачем, і успішне створення облікового запису. Використовуючи будь-яку частину Веб-сайту після створення облікового запису, ви погоджуєтесь з цією Угодою з користувачем.',
      text_1_3:
        'Перед створенням облікового запису необхідно уважно ознайомитися з цією Угодою з користувачем. Якщо ви не погоджуєтесь з положеннями цієї Угоди з користувачем, ви не повинні створювати обліковий запис або продовжувати використовувати Веб-сайт.',
      text_2_1:
        'Погоджуючись дотримуватися цієї Угоди, ви також погоджуєтеся дотримуватися Правил disapp.net та Політики конфіденційності, які включені цим посиланням за допомогою цієї Угоди. У разі будь-яких невідповідностей ця Угода користувача матиме переважну силу. Цим Ви заявляєте та гарантуєте, що:',
      text_2_1_1: 'Ви досягли (a) 18 років або (b) іншого встановленого законом віку, або повноліття, встановленого застосовними до вас законами, залежно від того, який вік більший;',
      text_2_1_2: 'У Вас є повна дієздатність для укладання юридично зобовязуючої угоди з нами, і ви не обмежені будь-якою формою обмеженої дієздатності;',
      text_2_1_3: 'Вся інформація, яку ви надаєте нам протягом терміну дії цієї угоди, є достовірною, повною, правильною, і ви повинні негайно повідомляти нас про будь-яку зміну такої інформації;',
      text_2_1_4: 'Ви несете одноосібну відповідальність за звітність та облік будь-яких податків, що застосовуються до вас відповідно до відповідного законодавства, за будь-які виграші, які ви отримуєте від нас;',
      text_2_1_5: 'Ви розумієте, що, використовуючи наші послуги, ви ризикуєте втратити гроші, внесені на ваш Обліковий рахунок, і погоджуєтеся з тим, що ви несете повну та одноосібну відповідальність за будь-які такі втрати;',
      text_2_1_6: 'В юрисдикції, в якій ви знаходитесь, вам дозволено користуватися послугами онлайн-казино;',
      text_2_1_7: 'Відносно депозитів та зняття коштів з вашого Облікового запису ви повинні використовувати тільки ті криптокотени, які дійсні та належать вам на законних підставах;',
      text_2_1_8: 'Ви розумієте, що вартість криптовалют може різко змінюватися в залежності від ринкової вартості;',
      text_2_1_9: 'Компютерне програмне забезпечення, компютерна графіка, веб-сайти та інтерфейс користувача, які ми надаємо вам, належать disapp.net або її партнерам і захищені законами про авторські права. Ви можете використовувати програмне забезпечення лише в особистих, розважальних цілях відповідно до всіх правил, користувальницької угоди, яку ми встановили, та відповідно до всіх застосовних законів, правил та положень;',
      text_2_1_10: 'Ви розумієте, що Ethereum не вважається законною валютою або платіжним засобом, і тому на Веб-сайті вони розглядаються як віртуальні засоби, які не мають внутрішньої цінності.',
      text_2_1_11: 'Ви підтверджуєте, що Ви не є посадовою особою, директором, співробітником, консультантом або агентом disapp.net або працюєте в будь-якій компанії, повязаній з disapp.net, або родичем або чоловіком будь-якого з перелічених вище;',
      text_2_1_12: 'Ви не діагностовані та не класифіковані як компульсивний або проблемний гравець. Ми не несемо відповідальності, якщо така проблема виникає при використанні наших послуг, але намагатимемося повідомити про відповідну доступну допомогу. Ми залишаємо за собою право вводити періоди обмірковування, якщо ми вважаємо, що такі дії будуть корисними.',
      text_2_1_13: 'Ви приймаєте та підтверджуєте, що ми залишаємо за собою право виявляти та запобігати використанню заборонених методів, включаючи, крім іншого, виявлення шахрайських транзакцій, автоматичну реєстрацію та реєстрацію, ігровий процес та методи захоплення екрану. Ці кроки можуть включати, крім іншого, перевірку властивостей пристрою Гравців, виявлення геолокації та маскування IP-адрес, транзакції та аналіз ланцюжка блоків;',
      text_2_1_14: 'Ви приймаєте наше право припиняти та/або змінювати будь-які ігри або події, запропоновані на Веб-сайті, а також відмовлятися від ставок та/або обмежувати їх.',
      text_2_1_15: 'Ви погоджуєтеся з тим, що ми маємо право забороняти/блокувати декілька облікових записів та вільно контролювати активи в таких облікових записах.',
      text_2_2:
        'Ми залишаємо за собою право оголосити ставку частково або повністю недійсною, якщо disapp.net на власний розсуд вважає очевидним, що сталося одна з наступних обставин:',
      text_2_2_1:
        'Ви або люди, повязані з вами, можете прямо або опосередковано вплинути на результат події, щоб отримати незаконну перевагу.',
      text_2_2_2:
        'Ви та/або люди, повязані з вами, прямо чи опосередковано уникаєте правил disapp.net',
      text_2_2_3:
        'На результат події прямо чи опосередковано вплинула злочинна діяльність.',
      text_2_2_4:
        'Були зроблені ставки, які інакше не були б прийняті, але які були прийняті в періоди, коли на веб-сайті виникали технічні проблеми.',
      text_2_2_5:
        'У звязку з такою помилкою, як помилка, вразливість, технічна помилка, форс-мажорні обставини або іншим чином, ставки були запропоновані, розміщені та прийняті через цю помилку.',
      text_2_2_6:
        'Якщо комісія за депозит гравця занадто мала і позначена блокчейном або аналогічним сайтом як "недостатньо комісії для передачі", disapp.net залишає за собою право конфіскувати виграш, якщо disapp.net на свій розсуд вважатимуть транзакцію та поведінку шахрайського гравця за своїм характером.',
      text_3_1:
        'Території, включені до чорного списку: Китай, Коста-Ріка, Нідерланди, Голландські Карибські острови, Кюрасао, Франція, Сполучені Штати та/або будь-які інші, обмежені законом країною або державою. Зверніть увагу, що категорично забороняється грати в ігри disapp.net у зазначених вище країнах, включених до чорного списку. Цим Ви даєте згоду на таке розкриття інформації.',
      text_4_1:
        'Ставку може зробити лише зареєстрований власник облікового запису.',
      text_4_2:
        'Ставку можна зробити тільки через інтернет.',
      text_4_3:
        'Ви можете зробити ставку тільки якщо у вас є достатній баланс на вашому рахунку з disapp.net',
      text_5_1:
        'disapp.net залишає за собою право скасувати будь-яку акцію, бонус або бонусну програму (включаючи, але не обмежуючись, бонусами за поповнення рахунку, запрошенням друзів для отримання бонусів та програмами лояльності) з негайним набуттям чинності, якщо ми вважаємо, що бонус був налаштований неправильно або зловживається, і якщо вказаний бонус був після виплати ми залишаємо за собою право відхилити будь-який запит на виведення коштів та списати таку суму з вашого рахунку. Чи вважається, що бонус було встановлено неправильно або зловживання, визначається виключно disapp.net',
      text_5_2:
        'Якщо ви використовуєте депозит на депозит, зняття вашого первинного депозиту не буде прийнято до тих пір, поки ви не досягнете вимог, передбачених Угодою про депозит на депозит.',
      text_5_3:
        'disapp.net залишає за собою право коригувати виплату за ставкою, зараховану на disapp.net рахунок, якщо він визначається disapp.net на власний розсуд, що така виплата була зарахована через помилку.',
      text_5_4:
        'Всі disapp.net пропозиції призначені для гравців-аматорів і disapp.net може на власний розсуд обмежити право клієнтів брати участь у всіх або частині будь-якої акції.',
      text_5_5: 'disapp.net залишає за собою право змінювати, скасовувати, відкликати або відмовлятися від будь-якої рекламної акції на свій розсуд.',
      text_5_6: 'Бонуси можуть бути отримані лише один раз на людину/обліковий запис, сімю, адресу, адресу електронної пошти, IP-адресу та середовище, в якому компютери використовуються спільно (університет, школа, публічна бібліотека, робоче місце тощо). ). Оператор залишає за собою право закрити ваш обліковий запис та конфіскувати будь-які існуючі кошти, якщо будуть виявлені докази зловживання/шахрайства.',
      text_5_7:
        'Ви визнаєте та розумієте, що існують окремі Угоди щодо рекламних акцій, бонусів та спеціальних пропозицій, які доповнюють цю Угоду. Ця Угода користувача викладена на відповідній сторінці контенту на цьому веб-сайті або була надана вам особисто, залежно від обставин. У разі суперечності між положеннями таких акцій, бонусів та спеціальних пропозицій та положеннями цієї Угоди, положення таких акцій, бонусів та спеціальних пропозицій будуть мати переважну силу.',
      text_5_8:
        'Ми можемо наполягати на тому, щоб ви поставили певну суму свого власного депозиту, перш ніж ви зможете робити ставки на будь-які безкоштовні/бонусні кошти, які ми зарахуємо на ваш рахунок.',
      text_6_1:
        'У рамках використання Веб-сайту ми можемо надати вам функцію живого чату, яка модерується нами та підлягає контролю. Ми залишаємо за собою право переглядати чат та вести облік усіх заяв на сайті.',
      text_6_2: 'Ми маємо право видалити функціональність чату або негайно закрити ваш обліковий запис Учасника та повернути баланс вашого облікового запису, якщо ви:',
      text_6_2_a: '(a) робите будь-які заяви сексуального характеру або грубо образливі, включаючи висловлювання нетерпимості, расизму, ненависті або ненормативної лексики;',
      text_6_2_b: '(b) робите заяви, які мають образливий, наклепницький, переслідуючий або образливий характер;',
      text_6_2_c: '(c) використовуєте чат для реклами, просування або іншого ставлення до будь-яких інших онлайн-обєктів;',
      text_6_2_d: '(d) робите заяви про disapp.net або будь-які інші інтернет-сайти, повязані з Веб-сайтом, які не відповідають дійсності, та/або є зловмисними, та/або завдають шкоди disapp.net;',
      text_6_2_e: '(e) використовуєте чат для змови, участі в незаконній поведінці або заохочення поведінки, яку ми вважаємо вкрай недоречною. Про будь-які підозрілі чати буде повідомлено компетентному органу.',
      text_6_3: 'Онлайн-чат використовується як форма спілкування між нами та вами, і його не можна копіювати або передавати будь-яким форумам або третім особам.',
      text_7_1:
        'Ви заходите на Веб-сайт і берете участь у Іграх на свій страх та ризик. Веб-сайт та Ігри надаються без будь-яких явних або певних гарантій.',
      text_7_2:
        'Без шкоди для загального характеру попереднього становища, ми, наші директори, співробітники, партнери, постачальники послуг.',
      text_7_3:
        ' Не гарантуємо відповідність програмного забезпечення, Ігор та Веб-сайту їхнім цілям.',
      text_7_4:
        'Не гарантуємо, що програмне забезпечення, Ігри та Веб-сайту не містять помилок.',
      text_7_5:
        'Не гарантуємо, що програмне забезпечення, Ігри та Веб-сайту будуть доступні без перерв.',
      text_7_6:
        'Не несемо відповідальності за будь-які збитки, витрати, витрати або збитки, будь то прямі, спеціальні, непрямі, випадкові або інші, що виникають у звязку з вашим використанням Веб-сайту або вашою участю в Іграх.',
      text_7_7:
        'Ви розумієте та визнаєте, що у разі збою в Ігри або її сумісності будь-які ставки, зроблені під час такого збою, будуть анульовані. Кошти, отримані в результаті несправної Ігри, вважаються недійсними, а також будь-які наступні ігрові раунди із зазначеними засобами, незалежно від того, в які Ігри граються з використанням таких засобів.',
      text_7_8:
        'Цим Ви погоджуєтесь повністю відшкодувати та убезпечити нас, наших директорів, співробітників, партнерів та постачальників послуг за будь-які витрати, збитки, збитки, претензії та зобовязання, незалежно від причин, які можуть виникнути у звязку з вашим використанням Веб-сайту або участю в Іграх .',
      text_7_9:
        'В межах, дозволених законом, наша максимальна відповідальність, що виникає в результаті або у звязку з вашим використанням Веб-сайту, незалежно від причини дій (будь то договір, правопорушення, порушення гарантії або інше), не перевищить 100 євро.',
      text_8_1: 'Якщо ви порушуєте будь-які положення цієї Угоди з користувачем або ми маємо розумні підстави підозрювати, що ви їх порушили, ми залишаємо за собою право не відкривати, не зупиняти або не закривати ваш рахунок Учасника, або не затримувати виплату вашого виграшу і застосовувати такі засоби до будь-якої шкоди, що належить вам.',
      text_8_2: 'Ви визнаєте, що disapp.net приймає остаточне рішення про те, чи ви порушували правила, умови або положення disapp.net таким чином, що це призводить до призупинення або постійної заборони на участь в іграх нашого сайту.'
    },
    faq: {
      name: {
        1: 'Чи можу я зареєструватися на вашому сайті?',
        2: 'Що, якщо я забуду свій пароль?',
        3: 'Я втратив свій мобільний телефон. Як скинути свій Google Authenticator?',
        4: 'Чи можу я змінити своє імя користувача або зареєстровану адресу електронної пошти?',
        5: 'Як мені стати VIP-персоною?',
        6: 'Як внести депозит?',
        7: 'Як вивести гроші?',
        8: 'Мінімальна сума виведення?',
        9: 'Скільки часу займає введення та виведення коштів?',
        10: 'Звідки надходять підтвердження транзакцій?',
        11: 'Скільки часу потрібно для підтвердження транзакції?',
        12: 'Чи є все це чесним і справедливим?',
        13: 'Що мені робити, якщо моя гра зависає або виникла проблема?'
      },
      text: {
        1: 'Вам має бути не менше 18 років або ви повинні досягти повноліття відповідно до вашої юрисдикції. Вам повинно бути дозволено грати в онлайн-ігри відповідно до законів, що застосовуються до вас. Для отримання додаткової інформації, будь ласка, ознайомтесь із нашими умовами. Ігри можуть викликати звикання, тому гравцям рекомендується зберігати самовладання.',
        2: 'Якщо ви забули свій пароль, ви можете скинути його протягом 15 секунд за посиланням «Забули пароль». Після подання заявки на скидання пароля виконайте інструкції в електронному листі, який ми надішлемо вам, щоб скинути пароль.',
        3: 'Якщо ви хочете видалити свій Google authenticator 2FA, будь ласка, звяжіться з нами. Після того, як ми отримаємо вашу заявку, для забезпечення безпеки вашого облікового запису вам необхідно правильно відповісти на кілька контрольних питань, щоб видалити 2FA.',
        4: 'Якщо ви наполягаєте на зміні свого імені користувача та/або зареєстрованої адреси електронної пошти, ви можете скинути його протягом 15 секунд за посиланням ”Скинути адресу електронної пошти',
        5: 'Ексклюзивний VIP-клуб доступний лише на запрошення. Ви отримаєте повідомлення про свій VIP-статус електронною поштою.',
        6: 'Знайдіть сторінку гаманця, перейдіть на сторінку поповнення рахунку, скопіюйте адресу гаманця або відскануйте QR-код для оплати. Вносите та виводьте засоби прямо з кооперативного гаманця.',
        7: 'Знайдіть сторінку гаманця, відкрийте сторінку виведення коштів, введіть адресу гаманця, на яку вам потрібно вивести, та суму криптовалюти (зверніть увагу на комісію). Після натискання кнопки підтвердження криптовалюта буде переведено на ваш гаманець (зверніть увагу на відповідну криптовалюту).',
        8: 'Оскільки вартість кожної криптовалюти різна, мінімальна сума виводу також різна',
        9: 'Кожна транзакція в блокчейні вимагає кількох циклів, щоб підтвердити, що переклад успішно виконано. Простіше кажучи, кожна транзакція потребує 5-10 хвилин, перш ніж вона буде підтверджена мережею блокчейнів. Якщо у вас виникнуть проблеми під час внесення або виведення коштів, ви можете відвідати сайт www.blockchain.info, щоб перевірити свою транзакцію, або звернутися до служби технічної підтримки.',
        10: 'Вся інформація про підтвердження надходить від постачальника гаманця, блокчейна та майнерів',
        11: 'Це залежить від блокчейну та вашої комісії за переклад. Зазвичай від 10 хвилин до кількох годин.',
        12: 'Ми засновані на смарт-контракт Ethereum. Всі дані та базова логіка в блокчейні прозорі, і маніпуляція з ними неможлива. Кожну транзакцію в блокчейні можна відстежити на Etherscan. Чесність, відкритість та справедливість нашої ігрової платформи гарантовані',
        13: 'Якщо ви зіткнетеся з будь-якою технічною проблемою під час гри, будь ласка, спробуйте оновити гру. Зазвичай, після оновлення проблем немає. Якщо проблема не зникне, будь ласка, звяжіться з нами.'
      }
    }
  },
  user_modal: {
    bet_amount: 'Сума ставок',
    total_games: 'Сіграно ігор',
    best_multiplier: 'Найкращий множник',
    best_winning: 'Кращий виграш'
  },
  vip_club: {
    title: 'VIP Клуби',
    sub_title: 'Підвищуйте свій рівень, щоб отримати унікальний доступ до щедрих нагород і персоналізованих подарунків!',
    current_level: 'Ваш рівень',
    current: 'Поточний',
    rakeback: 'Рейкбек',
    convert: 'Конвертувати',
    privilege: 'VIP Привілеї',
    current_scores: 'Набрані очки'
  },
  profile: {
    name: 'Налаштування облікового запису',
    myprofile: 'Мій профіль',
    promo: 'Ввести промокод',
    helpdesk: 'Центр допомоги',
    promo_input: 'Промокод',
    support: 'Чат підтримки',
    active_promo: 'Активуй промокод та отримай гроші на свій рахунок',
    bonus: 'Бонусна система',
    name_user: 'Імя користувача',
    edit_profile: 'Редагування профілю',
    user_info: 'Інформація про користувача',
    security: {
      name: 'Безпека',
      enter_login: 'Введіть логін',
      you_login: 'Логін',
      enter_login_minLength: 'мінімум 6 символів',
      new_password: 'Новий пароль',
      text_2fa: 'Введіть 6-значний код із програми',
      confirm_password: 'Повторіть пароль',
      enter_password: 'Введіть пароль',
      enter_password_minLength: 'мінімум 10 символів',
      password_has_uppercase: 'Пароль повинен містити велику літеру',
      password_has_lowercase: 'Пароль повинен містити маленьку літеру',
      password_has_number: 'Пароль повинен містити цифру',
      password_not_match: 'Паролі не співпадають',
      current_password: 'Поточний пароль',
      fa2_auth: 'Двофакторна авторизація',
      protect_account:
        'Захистіть свій обліковий запис Disapp додатковим рівнем безпеки.',
      enable_2FA: 'Увімкнути 2FA',
      disable_2FA: 'Вимкнути 2FA',
      button_save: 'Зберегти',
      uncorrect_login: 'Некоректний логін'
    },
    session: {
      name: 'Сесії',
      title: 'Сеанси',
      browser: 'Браузер',
      region: 'Регіон',
      ip: 'IP-Адреса',
      when: 'Коли',
      action: 'Дія',
      back: 'Назад',
      forward: 'Вперед',
      current: 'Поточна',
      completed: 'Завершена'
    },
    fa2: {
      name: 'Двофакторна автентифікація',
      1: 'Завантажте програму',
      2: 'Скачайте та встановіть',
      3: 'або',
      4: 'для вашого телефону або планшета',
      5: 'Відскануйте QR-код за допомогою камери вашого телефону.',
      6: '2FA КЛЮЧ (РУЧНЕ ВВЕДЕННЯ)',
      7: 'Введіть ваш пароль',
      8: 'Введіть 6-значний код із програми',
      9: 'Активувати',
      10: 'Деактивувати',
      11: 'Ключ скопійований у буфер обміну',
      12: 'ОТСКАНУЙ QR-код'
    },
    verify: {
      name: 'Верифікація',
      change: 'Зміна електронної пошти',
      invalid_file_type: 'Неприпустимий тип файлу',
      valid_file_type: 'Файл проходить віривацію'
    },
    general: {
      title: 'Спільне',
      min_length: 'Мінімум 3 символи',
      email: 'E-mail',
      login: 'Логін',
      confirm: 'Підтверджений',
      button_save: 'Зберегти',
      button_confirm: 'Підтвердити',
      button_sent: 'Надіслано',
      data_correctly: 'Заповніть всі дані коректно'
    },
    other: {
      name: 'Підключені послуги',
      social: 'Підключитись до стороннього сервісу'
    },
    success: {
      4: 'Успішно'
    },
    error: {
      2: 'Введіть коректне імя',
      11: 'Будь ласка, заповніть форму'
    }
  },
  chat: {
    chat_input: 'Введіть повідомлення...',
    not_auth: 'Щоб користуватися чатом, потрібно авторизуватися на сайті',
    error_load_data: 'Помилка завантаження даних',
    statistic: 'Статистика',
    favorite_games: 'Улюблені ігри',
    Bet: 'Ставок',
    try_luck:
      'Зазнай успіху теорії ймовірності, у грі яка заснована на розрахунку хеш-значення та алгоритмі блокчейна.',
    air_drop: {
      this_air_drop: 'Ви можете запустити свій особистий ейрдроп будь-якого токена для користувачів у чаті.',
      amount_activate: 'Кількість активацій',
      sum_air_drop: 'Сума ейрдропу',
      start_air_drop: 'Запустити',
      name: 'Ейрдроп',
      activated: 'Бонус активовано',
      select_currency: 'Виберіть валюту',
      choise_currency: 'Спосіб оплати',
      valid_limit: 'Введіть кількість активацій',
      valid_amount: 'Введіть суму ейрдропа',
      start: 'Запустив ейрдроп, вперед до успіху!',
      give_you: 'Ви отримаєте',
      activation: 'Активацій',
      activate: 'Активувати'
    },
    rules: {
      header: 'Правила чату',
      1: 'Не розсилайте спам, не ображайте інших користувачів. Крім того, уникайте використання ВЕЛИКИХ БУКВ! Ніхто не любить, коли на нього кричать!',
      2: 'Не просимо і не просіть кредитів, аірдропів та переказів.',
      3: 'Не намагайтеся обдурити користувачів.',
      4: 'Не займайтеся жодними формами реклами/торгівлі/продажу/купівлі або пропозиції послуг.',
      5: 'Не використовуйте сервіси для скорочення URL-адрес. Завжди надсилайте вихідне посилання.',
      6: 'Використовуйте відповідні мовні канали.'
    }
  },
  menu: {
    tooltip: {
      settings: 'Налаштування',
      level: 'Капрал(3 рівень)',
      more: 'Дізнатися більше',
      exit: 'Вийти'
    }
  },
  history: {
    title: 'Історія ігор',
    my_bets: 'Мої ставки',
    all_bets: 'Всі ставки',
    big_wins: 'Великі виграші',
    cool_moments: 'Круті моменти',
    game: 'Гра',
    gamer: 'Гравець',
    time: 'Час',
    bet: 'Ставка',
    coefficient: 'Коефіцієнт',
    winnings: 'Виграш'
  }
}
